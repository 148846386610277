
  <!--
  <ion-toolbar>
    <ion-buttons left>
      <ion-button style="background-color: rgba(0,0,0,0)" (click)="close()">
        <ion-icon name="arrow-back" style="text-transform: none; color: #d6f4fc!important; margin-right: 5px;"></ion-icon><span style="color: #d6f4fc!important; font-size:12pt; text-transform: none!important;">Back</span>
      </ion-button>
    </ion-buttons>
    <ion-title>{{fileName}}</ion-title>
  </ion-toolbar>
  -->

<ion-header id="memberHeader">
    <ion-toolbar color ="primary">
      <ion-buttons slot="start">
        <ion-button (click)="close()" >
          <ion-icon name="arrow-back-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons> 
      <ion-title>{{fileName}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
  <div [ngClass]="{'border': showBorder}">
    <pdf-viewer [src]="getPdfUrl()" [render-text]="true" [fit-to-page]="true"   
      style="border: 1px solid gray;width: 400px; height: 700px">
    </pdf-viewer>
  </div>
</ion-content>
import { Injectable } from '@angular/core';
import { Utilities } from './utilities';
import { SessionData } from './session-data';
import { Adapter } from './adapter';
//import { Platform } from 'ionic-angular';
import  {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SingleSignOn {

  public static readonly PROVIDER_SEARCH = 'providerSearch';
  public static readonly CAREMARK = 'caremark';
  public static readonly CAREMARK_PHARMACY = 'caremarkPharmacy';
  public static readonly WELLNESS = 'wellness';
  public static readonly LIMEADE = 'limeade';
  public static readonly TELADOC = 'teladoc';
  public static readonly HEALTH_EQUITY = 'healthEquity';
  public static readonly RX_SHOPPER = 'rxShopper';
  public static readonly BEND_FINANCIAL = 'bendFinancial';
  public static readonly OCT_TMP_MA = 'otcTMPMA';

  public static readonly OPTUM = 'optum';
  public static readonly OPTUM_MEDICATION = 'optumMedication';

  constructor(private utilityHandler: Utilities, private sessionDataHandler: SessionData, private adapterHandler: Adapter, private platform: Platform) {
  }

  // TODO: this should just take in a url, and loose this switchcase.

  callSSO(vendor: string, showMessage: boolean) : Promise<void> {
    return new Promise(() => {

      let smsession = 'cookieValue=' + encodeURIComponent(this.sessionDataHandler.smSessionToken);
      let url: string;
      let para = "";
      let newUrl = "";
      let tag: string[] = [];

      switch(vendor) {
        case SingleSignOn.PROVIDER_SEARCH: {
          tag = ["MMA_DOCTOR_SEARCH","SSO to Doctor Search"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.PROVIDER_SEARCH_URL]);
          break;
        }
        case SingleSignOn.CAREMARK: {
          tag = ["MMA_CAREMARK_SSO_HOME","Caremark Homepage SSO Clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.CAREMARK_URL]);
          break;
        }
        case SingleSignOn.CAREMARK_PHARMACY: {
          tag = ["MMA_CAREMARK_SSO_FORMULARY","Caremark Drug Formulary SSO Clicked "];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.CAREMARK_PHARMACY_URL]);
          break;
        }
        case SingleSignOn.OPTUM: {
          tag = ["MMA_OPTUM_SSO_HOME","Optum Homepage SSO Clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.OPTUM_URL]);
          break;
        }
        case SingleSignOn.OPTUM_MEDICATION: {
          tag = ["MMA_OPTUM_SSO_FORMULARY","Optum Drug Formulary SSO Clicked "];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.OPTUM_MEDICATION_URL]);
          break;
        }
        case SingleSignOn.WELLNESS: {
          tag = ["MMA_WBA","Optum for the Wellbeing Assessment Homepage SSO Clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.WELLNESS_URL]);
          break;
        }
        case SingleSignOn.LIMEADE: {
          tag = ["MMA_WBA","Health and Wellness SSO Clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.LIMEADE_URL]);
          break;
        }
        case SingleSignOn.TELADOC: {
          tag = ["MMA_TELADOC_SSO","Teladoc Homepage SSO Clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.TELADOC_URL]);
          break;
        }
        case SingleSignOn.HEALTH_EQUITY: {
          tag = ["MMA_HRA_HSA","Health Equity SSO to HRA / HSA clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.HEALTH_EQUITY_URL]);
          break;
        }
        case SingleSignOn.RX_SHOPPER: {
          tag = ["MMA_RX_SHOPPER","RX Shopper SSO clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.RX_SHOPPER_URL]);
          break;
        }
        case SingleSignOn.BEND_FINANCIAL: {
          tag = ["MMA_BEND_SSO","Bend Financial HSA SSO clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.BEND_FINANCIAL_URL]);
          break;
        }
        case SingleSignOn.OCT_TMP_MA: {
          tag = ["MMA_OTC","OTC SSO clicked"];
          url = 'redirectUrl=' + encodeURIComponent(this.sessionDataHandler.memberProfileData[SessionData.OTC_TMP_MA_URL]);
          break;
        }
        default: { 
          url = 'redirectUrl=error';
          break;
        } 
      }
      para = smsession + '&' + url;
      newUrl = this.sessionDataHandler.memberProfileData[SessionData.SSO_REDIRECT_URL] + '?' + para;
      let isSafari = this.utilityHandler.isMobileBrowser() && this.platform.is('ios');

      if (showMessage && isSafari) {
        this.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], tag);
        this.utilityHandler.openLinkWithWarning(newUrl);
      } else if (showMessage && !isSafari) {
        this.utilityHandler.openLinkWithWarning(newUrl, () => this.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], tag));
      } else if (!showMessage && isSafari) {
        this.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], tag);
        this.utilityHandler.openLink(newUrl);
      } else {
        this.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], tag).then(() => {
          this.utilityHandler.openLink(newUrl);
        });
      }
    });
  }
}

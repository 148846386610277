import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Utilities } from '../../providers/utilities';
import { SessionData } from '../../providers/session-data';
import { SessionHandler } from '../../providers/session-handler';
import { SingleSignOn } from '../../providers/single-sign-on';
import {Router} from '@angular/router';
import { Adapter } from '../../providers/adapter';

/*
import { ContactUsPage } from '../../pages/contact-us/contact-us';
import { LegalInformationPage } from '../../pages/legal-information/legal-information';
import { EdeliverypreferencesPage } from '../../pages/edeliverypreferences/edeliverypreferences';
import { AboutThpPage } from '../../pages/about-thp/about-thp';
import { ReferralsLandingPage } from '../../pages/referrals-landing/referrals-landing';
import { USFHPPharmacyPage } from '../../pages/usfhp-pharmacy/usfhp-pharmacy';
import { DrugCoveragePage } from '../../pages/drug-coverage/drug-coverage';
import { DrugCoverageTmpLitePage } from '../../pages/drug-coverage-tmp-lite/drug-coverage-tmp-lite';
import { NotificationsLandingPage } from '../../pages/notifications-landing/notifications-landing';
import { ManageMyAccountPage } from '../../pages/manage-my-account/manage-my-account';
import { WellnessLandingPage } from '../../pages/wellness-landing/wellness-landing';
import { ProviderSearchInfoPage } from '../../pages/provider-search-info/provider-search-info';
import { TaxFormsLandingPage } from '../../pages/tax-forms-landing/tax-forms-landing';
//import { IdentityService } from '../../providers/identity-service';
*/

import { TeladocService } from '../../providers/teladoc-service';
//import { AdvanceExplanationOfBenefitsPage } from '../../pages/advance-explanation-of-benefits/advance-explanation-of-benefits';
import { LdapGroups } from '../../domain/constants/LdapGroups';

@Component({
  selector: 'more-pop-component',
  templateUrl: 'more-pop-component.html',
  styleUrls: ['./menu-pop-component.scss'],
})
export class MorePopComponent {
  

  //memberName: string = "";
  //memberId: string = "";
  //planName: string = "";
  //initials: string;
  //memberType = "";
  //shouldDisplayLoginPreferences = true;
  //isTMP = false;
  //isMedSuppMember: boolean = false;

  constructor(private utilityHandler: Utilities,
    public sessionDataHandler: SessionData,
    private menu: MenuController,
    //private platform: Platform,
    private ssoHandler: SingleSignOn,
    private sessionHandler: SessionHandler,
    //private identity: IdentityService,
    private teladoc: TeladocService,
    private adapterHandler: Adapter,
    private router: Router
    ) {
      
  }


  public openMenu() {
    this.menu.enable(true, 'menuPop');
    this.menu.open('menuPop');
  }

  closeMenu() {
    this.menu.close("menuPop");
  }

  /*
  updateMoreMenu() {
    
    //if (this.sessionDataHandler.memberProfileData) {
      //this.memberName = this.sessionDataHandler.memberProfileData[SessionData.FULL_NAME];
      //this.memberId = "Member # " + this.sessionDataHandler.memberProfileData[SessionData.FORMATTED_MEMBER_ID];
      //this.planName = this.sessionDataHandler.memberProfileData[SessionData.PLAN_NAME];
      //this.initials = this.sessionDataHandler.memberProfileData[SessionData.INITIALS];
      //this.memberType = this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE];
      //this.isMedSuppMember = this.sessionDataHandler.memberProfileData[SessionData.TMP_MED_SUPP_MEMBER];
      //if (this.utilityHandler.isBrowser()) {
       // this.shouldDisplayLoginPreferences = false;
      //}
    //}
    
   
  }*/

  showSop() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_SOP];
    } 
    return false;
    
  }

  showPharmacy() {
    if (this.sessionDataHandler.memberProfileData) {
      return (this.sessionDataHandler.memberProfileData[SessionData.HAS_COMM_PHARMACY] || this.sessionDataHandler.memberProfileData[SessionData.THPP_MA_MEMBER] || this.sessionDataHandler.memberProfileData[SessionData.THPP_RI_MEMBER]);
    }
    return false;
    
  }

  showUSFHPPharmacy() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_USFHP_GROUP];
    } 
    return false;
    
  }

  showTMPPharmacy() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_TMP_PHARMACY];
    } 
    return false;
    
  }

  showTMPPharmacyLitePage() {
    if (this.sessionDataHandler.memberProfileData) {
      if (!this.sessionDataHandler.memberProfileData[SessionData.HAS_TMP_PHARMACY] && this.sessionDataHandler.memberProfileData[SessionData.HAS_TMP_GROUP]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  //Todo: Remove after sept release or when Tax docs is added.
  showFinancial() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_SOP] || this.sessionDataHandler.memberProfileData[SessionData.HAS_TAX_DOCS];
    } 
    return false;
    
  }

  
  

  showWellness(){
    if ( this.sessionDataHandler.memberProfileData) { 
      if( this.sessionDataHandler.memberProfileData[SessionData.HAS_ACTIVE_COVERAGE]){
        if(  this.sessionDataHandler.memberProfileData[SessionData.HAS_COMM_ACTIVE] 
             //|| this.sessionDataHandler.memberProfileData[SessionData.THPP_MA_MEMBER] ||
             //this.sessionDataHandler.memberProfileData[SessionData.THPP_RI_MEMBER]
             ){      
              return true;
        }
      }
    }
    return false;
  }
  

  openContactUsPage() {
    //this.app.getRootNav().push(ContactUsPage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/home/contactus'], { skipLocationChange: true});
    this.menu.close();
  }
  openLegalInfoPage() {
    //this.app.getRootNav().push(LegalInformationPage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/home/legalinformation'], { skipLocationChange: true});
    
    this.menu.close();
  }

  dismissPopover() {
    this.menu.close();
  }

  openHomePage() {
    //this.app.getRootNav().pop();
    this.router.navigate(['/home'], { skipLocationChange: true});
    this.menu.close();
  }

  goToReferrals() {
    //this.app.getRootNav().push(ReferralsLandingPage, {}, { animate: true, direction: 'left' });
    this.router.navigate(['/home/reflandingpage'], { skipLocationChange: true});
    this.menu.close();
  }

  goToProviderSearch(vendor: string) {
    if(this.sessionDataHandler.memberProfileData[SessionData.TMP_MED_SUPP_MEMBER]){
    //if(this.isMedSuppMember){
      //this.app.getRootNav().push(ProviderSearchInfoPage, {}, { animate: true, direction: 'top' });
      this.router.navigate(['/home/provsearchinfo'], { skipLocationChange: true});
      this.menu.close();
    } else {
      this.ssoHandler.callSSO(vendor, false);
      this.menu.close();
    }
  }

  goToSop() {
    //this.app.getRootNav().push(SummaryOfPaymentPage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/home/summarypayment'], { skipLocationChange: true});
    this.menu.close();
  }

  userLogOut() {
   
    this.sessionHandler.clearSession();
    this.router.navigate(['/userlogin'], { skipLocationChange: true});
    this.menu.close();

  }

  //TODO: The below are to be moved after sept. release 2018
  // seems nolonger calling from more page
  /*
  EdeliverypreferencesPage() {
    //this.app.getRootNav().push(EdeliverypreferencesPage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/edeliverypreferences'], { skipLocationChange: true});
    this.menu.close();
  }*/

  gotoAboutThpPage() {
    //this.app.getRootNav().push(AboutThpPage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/home/about'], { skipLocationChange: true});
    this.menu.close();
  }

  gotoLoginPreferencesPage() {
    //this.app.getRootNav().push(LoginPreferencesPage, {}, { animate: true, direction: 'top' });
    //this.router.navigate([''], { skipLocationChange: true});
    this.menu.close();
  }

  goToUsfhpPharmacyPage() {
    //this.app.getRootNav().push(USFHPPharmacyPage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/home/usfhppharmacy'], { skipLocationChange: true});
    this.menu.close();
  }

  goToTmpPharmacyPage() {
    //this.app.getRootNav().push(DrugCoveragePage, {}, { animate: true, direction: 'top' });
    // user story # 39797
    this.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], ["MMA_PHARMACY_CONTENT", "Pharmacy Content"]);
    this.router.navigate(['/home/drugcoverage'], { skipLocationChange: true});
    this.menu.close();
  }

  goToTmpLitePharmacyPage() {
    //this.app.getRootNav().push(DrugCoverageTmpLitePage, {}, { animate: true, direction: 'top' });
    this.router.navigate(['/home/drugcoveragetmplite'], { skipLocationChange: true});
    this.menu.close();
  }

  goToCommPharmacy() {
    //this.ssoHandler.callSSO('/home/caremark', true);
    this.goToTmpPharmacyPage();
    this.menu.close();
  }

  isTMPMember() {
    //return this.memberType == SessionData.MEMBER_TYPE_TMP;
    //console.log('this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] *** ', this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE]);
    if (this.sessionDataHandler.memberProfileData) {
      const memType = this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE];
      return memType == SessionData.MEMBER_TYPE_TMP;
    }
    return false;
    
    //console.log('SessionData.MEMBER_TYPE_TMP ', SessionData.MEMBER_TYPE_TMP);
    //return this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] = SessionData.MEMBER_TYPE_TMP;
  }

  gotoNotificationsPage() {
    //this.app.getRootNav().push(NotificationsLandingPage, {}, {animate: true, direction: 'top'});
    this.router.navigate(['/home/notificationslanding'], { skipLocationChange: true});
    this.menu.close();
  }

  goToManageMyAccount() {
    //this.app.getRootNav().push(ManageMyAccountPage, {}, {animate: true, direction: 'top'});
    this.router.navigate(['/home/managemyaccount'], { skipLocationChange: true});
    this.menu.close();
  }

  goToWellnessSSO() {
    //this.app.getRootNav().push(WellnessLandingPage, {}, {animate: true, direction: 'top'});
    this.router.navigate(['/home/wellnesslanding'], { skipLocationChange: true});
    this.menu.close();
  }

  goToTeladoc() {
    if(this.utilityHandler.isMobileBrowser()) {
      this.teladoc.openTeladocSSO();
    } else {
      this.teladoc.openTeladocSDK();
    }
    this.menu.close();
  }

  showTeladoc() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_TELADOC_GROUP];
    }
    return false;
  }

  goToTaxForms() {
    //this.app.getRootNav().push(TaxFormsLandingPage, {}, {animate: true, direction: 'top'});
    this.router.navigate(['/home/taxformslanding'], { skipLocationChange: true});
    this.menu.close();
  }

  showTaxForm() {
    if(this.sessionDataHandler.memberProfileData) {
      return  this.sessionDataHandler.memberProfileData[SessionData.HAS_TAX_FORM_GROUP];
    } else {
      return false;
    }
  }

  goToHealthEquity() : void {
    this.ssoHandler.callSSO('healthEquity', true);
    this.menu.close();
  }

  showHealthEquity() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_HEALTH_EQUITY_GROUP];
    }
    return false;
  }

  /*
  showRxShopper() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_RX_SHOPPER_GROUP];
    }
    return false;
  }*/

  goToRxShopperPage() : void {
    this.ssoHandler.callSSO('rxShopper', true);
    this.menu.close();
  }

  showBendFinancial() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_BEND_FINANCIAL_GROUP];
    }
    return false;
  }

  goToBendFinancial() {
    this.ssoHandler.callSSO('bendFinancial', true);
    this.menu.close();
  }

  showAeob() {
    if (this.sessionDataHandler.memberProfileData) {
      return this.sessionDataHandler.memberProfileData[SessionData.HAS_AEOB_GROUP];
    }
    return false;
  }
  
  goToAeob() {
    //this.app.getRootNav().push(AdvanceExplanationOfBenefitsPage, {}, {animate: true, direction: 'top'});
    this.router.navigate(['/home/advexpbenefits'], { skipLocationChange: true});
    this.menu.close();
  }

  showOtc() {
    return this.sessionDataHandler.groups?.length && this.sessionDataHandler.groups.includes(LdapGroups.GROUP_OTC_TMP_MA);
  }

  goToOtc() {
    this.ssoHandler.callSSO(SingleSignOn.OCT_TMP_MA, true);
    this.menu.close();
  }

  showCob() {
    if (this.sessionDataHandler.memberProfileData) {
      return (this.sessionDataHandler.memberProfileData[SessionData.THPP_MA_MEMBER] || this.sessionDataHandler.memberProfileData[SessionData.THPP_RI_MEMBER]);
    }
    return false;    
  }

  goToCob() {
    this.router.navigate(['/home/coordinationofbenefits'], { skipLocationChange: true});
    this.menu.close();
  }

   openAuthForm(){
    if (this.sessionDataHandler.memberProfileData) {
      if (this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_TMP){
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/673a4832-949b-4152-84c7-5eee47c696af/launch/index.html?form=F_Form1");
      }else if (this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_COMM){
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/dc504ac3-f449-431e-8dfa-396b22ac2696/launch/index.html?form=F_Form1");
      }else if (this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_THPP_MA || this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_THPP_RI){
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/edbc939b-9ad4-4109-870f-10409ad662ae/launch/index.html?form=F_Form1");
      }else if(this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_USFHP || this.sessionDataHandler.memberProfileData[SessionData.HAS_USFHP_GROUP]) {
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/ffd3cc2d-0a74-403d-8bfa-19dcd2fdf986/launch/index.html?form=F_Form1");
      }
    }
  }

  openDesigForm(){
    if (this.sessionDataHandler.memberProfileData) {
      if (this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_TMP){
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/26020da2-e4de-419d-8cdb-5f3c6c9391a9/launch/index.html?form=F_Form1");
      }else if (this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_COMM){
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/c34f4d8e-1ce7-47e9-851c-c0351d97ad88/launch/index.html?form=F_Form1");
      }else if (this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_THPP_MA || this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_THPP_RI){
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/4568fec7-0032-40f2-8f67-40347ecae643/launch/index.html?form=F_Form1");
      }else if(this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE] == SessionData.MEMBER_TYPE_USFHP || this.sessionDataHandler.memberProfileData[SessionData.HAS_USFHP_GROUP]) {
        this.utilityHandler.openLink("https://formseb.tufts-health.com/forms/landing/org/app/57fd719b-ba23-4b7e-82b6-5f7eb29bb2b4/launch/index.html?form=F_Form1");
      }
    }
  }
}
import { Injectable } from '@angular/core';
import { MenuController} from '@ionic/angular';
//import 'rxjs/add/operator/map';
import { LocalStorage } from './local-storage';
import { SessionData } from './session-data';
//import { Http, Headers } from '@angular/http';
import {HttpClient,HttpHeaders} from '@angular/common/http';
//import { AlertController, App } from 'ionic-angular';
import { AlertController } from '@ionic/angular';
import { TeladocService } from './teladoc-service';
import { VaultService } from './vault-service';
import { Adapter } from './adapter';
import { Utilities } from './utilities';
//import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
//import { Keepalive } from '@ng-idle/keepalive';
import {Router} from '@angular/router';
//import { Location } from '@angular/common';
import { PopoverController } from '@ionic/angular';
import {  ModalController } from '@ionic/angular';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';


@Injectable({
  providedIn: 'root',
})
export class SessionHandler {
    private static STORAGE_KEY = 'smsession';
    private static LOGGEDOFF = 'LOGGEDOFF';

    constructor(//private app: App,
        private http: HttpClient, private localStorage: LocalStorage, private menu: MenuController,
        private sessionData: SessionData, private alertCtrl: AlertController,
        private popover: PopoverController, private modelover: ModalController,
        private teladoc: TeladocService, private httpClient: HttpClient,
        private vaultService:  VaultService,
        private idle: Idle, 
        private router: Router,private adapterHandler: Adapter,private utilities: Utilities) {       
    }

    getSession(): string {
        let smsession = '';
        this.localStorage.getLocalStorageItemAsync(SessionHandler.STORAGE_KEY).then((data:string) => {
            smsession = data;
       });
       return smsession;
    }

    async setSession(newSession:string) {
        
        if (newSession === SessionHandler.LOGGEDOFF) {
          this.localStorage.setUnsecureLocalStorageItem(SessionHandler.STORAGE_KEY, '');
          const alert = await this.alertCtrl.create({
            //cssClass: 'my-custom-class',
            header: 'Session Time out',
            //subHeader: 'Subtitle',
            message: 'You have reached the session limit. For your protection, sessions are open for a limited period of time on our mobile application. For your security you must log back into the application.',
            buttons: ['OK']
          });
          await alert.present();
          await alert.onDidDismiss().then(() => {
            this.sessionData.clearSessionData();
          });
        }else{
          this.localStorage.setUnsecureLocalStorageItem(SessionHandler.STORAGE_KEY, newSession);
        }
    }
    
    callLogout() {
      this.router.navigate(['userlogin'], { skipLocationChange: true});
      this.utilities.showAlert("Alert", "", "You have been logged out due to inactivity. Please log back in to resume your session.", "OK");
      this.clearSession(); 

    }
    
    async clearSession(){
     
      this.menu.close("menuPop");
      if (await this.popover.getTop()) {
        this.popover.dismiss();
      }
      if (await this.modelover.getTop()) {
        this.modelover.dismiss();
      }
      this.sessionData.userLogout = true;
      this.sessionData.userLoggedIn = false;
      this.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], ["MMA_MEMBER_LOGOUT", "Member Logout"]);
      
      if (!this.utilities.isMobileBrowser() && this.teladoc) {
        this.teladoc.logout();
      }
      if (!this.utilities.isMobileBrowser() ){ 
        if( ! await this.vaultService.isVaultEmpty()){
          this.vaultService.lockVault();
        }
      }
      if (this.sessionData.isImpersonating) {
          this.logoutWithFCC();
      } else {
          this.logoutWithSM();
      }
      this.sessionData.clearSessionData();
      if(this.idle.isRunning()){
        this.idle.stop();
      }

    }

    logoutWithSM() {
        //var headers = new Headers();
        //headers.append('SMSESSION', this.sessionData.smSessionToken);
        var url = this.sessionData.memberProfileData[SessionData.LOGOUT_USER] + 'siteminderagent/forms/logout.html';
        const headers = { 'SMSESSION': this.sessionData.smSessionToken }
        this.http.get<any>(url, { headers }).subscribe(data => {});
    }
    
    logoutWithFCC(): void {
        console.log('calling : logoutWithSM');
        let url = this.sessionData.memberProfileData[SessionData.LOGOUT_USER] + 'sec/impersonator/mobilemember/end_imp.fcc';
        //let url = window.location.protocol+"//"+window.location.host+'/sec/impersonator/mobilemember/end_imp.fcc';
        this.sessionData.clearSessionData();
        this.utilities.openLinkViaLocation(url);
        //this.utilities.openLink(url);
    }
}

import { HttpClientModule } from '@angular/common/http';
import { NgModule ,APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule ,IonicRouteStrategy} from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

//import { MenuController } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { IonicStorageModule } from '@ionic/storage';
//import { IonicStorageModule } from '@ionic/storage-angular';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
//import { Drivers } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { WrongApp } from './providers/wrong-app-handler';

//import { IdentityService } from './providers/identity-service';
import { VaultService } from './providers/vault-service';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 
import {MorePopModule} from './app-common-module/MenuPopModule';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';




//import { SessionData } from './providers/session-data';
//import { LocalStorage } from './providers/local-storage';
//import { StorageHandler } from './providers/storage-handler';
//import { FingerPrintAuth } from './providers/finger-print-auth';
//import { Utilities } from './providers/utilities';
//import  {PdfHandler} from './providers/pdf-handler';
//import { TouchID } from '@awesome-cordova-plugins/touch-id/ngx';
//import {LoginOptionsService} from './providers/login-options';
//import  {BrowserAuthPlugin}  from './providers/browser-auth-plugin';
import {BrowserAuthService} from './providers/browser-auth-service';
//import { Idle } from "@ng-idle/core";
import { TeladocService } from './providers/teladoc-service';
//import { SingleSignOn } from './providers/single-sign-on';

import {Adapter} from './providers/adapter';
//import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { Market } from '@ionic-native/market/ngx';
import {APP_BASE_HREF} from '@angular/common';



//import {  AppHeaderComponent} from './components/app-header-component/app-header-component';
//import {  AppFooterComponent} from './components/app-footer-component/app-footer-component';
//import {HeaderModule} from './components/HeaderModule';
//import  {HomePageModule} from './pages/home/home.module';

const appInitFactory =
  (vaultService: VaultService): (() => Promise<void>) =>
  () =>
    vaultService.init();


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MorePopModule,
    NgIdleKeepaliveModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: APP_BASE_HREF, useValue:  '/' + (window.location.pathname.split('/')[1] || '')},
    File,FileOpener,
    Device,StatusBar,Keyboard,AppVersion,
    Platform,WrongApp,Market,ScreenOrientation,
    /*
     Platform,WrongApp,SessionData,LocalStorage,StorageHandler,
    Utilities,PdfHandler,TeladocService,SingleSignOn,LoginOptionsService,BrowserAuthPlugin,
    */

    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [VaultService],
      multi: true,
    },
    
    
    ],
  bootstrap: [AppComponent]
})
export class AppModule {}

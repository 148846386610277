import { Injectable, NgZone } from '@angular/core';
//import { Capacitor } from '@capacitor/core';
//import { Utilities } from './utilities';
import {
  Vault,
  Device,
  DeviceSecurityType,
  VaultType,
  BrowserVault,
  VaultErrorCodes,
  DeviceErrorCodes,
  BiometricPermissionState,
  BiometricSecurityStrength,
  IdentityVaultConfig,
} from '@ionic-enterprise/identity-vault';
import { Platform } from '@ionic/angular';
import { Member } from "../domain/user/member";
import {Utilities} from '../providers/utilities';
import {LocalStorage} from '../providers/local-storage';
//import {SessionHandler} from '../providers/session-handler';


const config: IdentityVaultConfig = {
  key: 'io.ionic.point32.key',
  type: VaultType.SecureStorage,
  deviceSecurityType: DeviceSecurityType.Both,
  lockAfterBackgrounded:  300000, // 5 min
  shouldClearVaultAfterTooManyFailedAttempts: true,
  customPasscodeInvalidUnlockAttempts: 3,
  unlockVaultOnLoad: false
};
const key = 'sessionData';


export interface VaultServiceState {
  session: Member;
  isLocked: boolean;
  privacyScreen: boolean;
  lockType: 'NoLocking' | 'Biometrics' | 'SystemPasscode';
  canUseBiometrics: boolean;
  canUsePasscode: boolean;
  isEmpty: boolean;
}



@Injectable({ providedIn: 'root' })
export class VaultService  {
  
  public state: VaultServiceState = {
    session: null,
    isLocked: false,
    privacyScreen: false,
    lockType: 'NoLocking',
    canUseBiometrics: false,
    canUsePasscode: false,
    isEmpty: true,
    
  };

  vault: Vault | BrowserVault;

  constructor(private ngZone: NgZone, private platform: Platform, private utilities: Utilities, private localStorge: LocalStorage) {
    
   }

  async init() {
    await this.platform.ready(); // This is required only for Cordova
    if(! this.utilities.isMobileBrowser()){
        this.vault = (this.platform.is('desktop') ||  this.platform.is('mobileweb')) ? new BrowserVault(config) : new Vault(config);
        await Device.setHideScreenOnBackground(true);       
        
        try{
          this.vault.onLock(() => {
            console.log('this.vault.onLock(()');
            this.ngZone.run(() => {
            this.state.isLocked = true;
            this.state.session = undefined;
            });
          });
        }catch(e) {
          console.log('error  onLock',e);
        } 
        try{
          this.vault.onUnlock(() => {
            console.log('this.vault.onUnlock(()');
            this.ngZone.run(() => {
              this.state.isLocked = false;
              this.restoreSession();
            });
          });
        }catch(e) {
          console.log('error  onUnlock',e);
        } 
        
        this.state.privacyScreen =
        (this.platform.is('hybrid')) ? true : await Device.isHideScreenOnBackgroundEnabled();
        this.state.canUseBiometrics = (this.platform.is('hybrid'))  ? true : await Device.isBiometricsEnabled();
        this.state.canUsePasscode = (this.platform.is('hybrid'))  ? true : await Device.isSystemPasscodeSet();
    }
    
  }

  async unlock(): Promise<void> {

    try {
      this.vault.unlock();
    } catch(e) {
      switch (e.code) {
        case VaultErrorCodes.AuthFailed:
          alert('You failed to authenticate!');
          break;
        case VaultErrorCodes.UserCanceledInteraction:
          alert('You cancelled the face id prompt!');
          break;
        default:
          throw e;
      }
    }
  }

  async setSession(value: Member): Promise<void> {
    this.state.session = value;
    await this.vault.setValue(key, value);
    this.state.isEmpty = await this.vault.isEmpty();
    
  }
  

  async restoreSession()  {
    const value = await this.vault.getValue(key);
    this.state.session = value;  
  }
  
  async isLocked(){
    return await this.vault.isLocked();
  }

  async lockVault() {
    await this.vault.lock();
    
  }

  async unlockVault() {
    await this.vault.unlock();
  }

  async isVaultEmpty() {
    const vaultIsEmpty = await this.vault.isEmpty();
    if (vaultIsEmpty) {
      return true;
    }else{
      return false;
    }
  }

  setPrivacyScreen(enabled: boolean) {
    Device.setHideScreenOnBackground(enabled);
    this.state.privacyScreen = enabled;
  }

  async setLockType(bothBiometrics : boolean) {
    let type: VaultType;
    let deviceSecurityType: DeviceSecurityType;
    try{
      switch (this.state.lockType) {
        case 'Biometrics':
          type = VaultType.DeviceSecurity;
          if(bothBiometrics){
            deviceSecurityType = DeviceSecurityType.Both;
          }else{
            deviceSecurityType = DeviceSecurityType.Biometrics;
          }
          break;
        case 'SystemPasscode':
          type = VaultType.DeviceSecurity;
          deviceSecurityType = DeviceSecurityType.SystemPasscode;
          break;
        default:
          type = VaultType.SecureStorage;
          deviceSecurityType = DeviceSecurityType.None;
          break;
      }
      await this.vault.updateConfig({
        ...this.vault.config,
        type,
        deviceSecurityType,
      });
    }catch(e) {
      console.log('error from setLockType : ', e);
    }
  }

  async clearVault() {
    try{
      await this.vault.clear();
      this.state.lockType = 'NoLocking';
      this.state.session = undefined;
      this.state.isEmpty = await this.vault.isEmpty();
    }catch(e) {
      console.log('clearValut error ',e);
    }      
  }

  /*
  async showBiometicPrompt(): Promise<string> {
    const iosPromptConfig = {
      iosBiometricsLocalizedReason : 'Approve Face ID',
      iosBiometricsLocalizedCancelTitle: 'Cancel'
    };

    const androidPromptConfig = {
      androidBiometricsNegativeButtonText: 'Cancel',
      androidBiometricsPromptDescription : '',
      androidBiometricsPromptSubtitle: '',
      androidBiometricsPromptTitle : 'Please Authenticate'
    };

    try{
      if(this.platform.is('android')){
        await Device.showBiometricPrompt(androidPromptConfig);
      }else {
        await Device.showBiometricPrompt(iosPromptConfig);
      }
      return 'ACCEPTED';
    } catch (e) {
      console.log('showBiometicPromt  ***** ',e.code);
      switch (e.code) {
        case DeviceErrorCodes.SecurityNotAvailable: { // code 3
          console.log('User selected  Do not Allow from Biometic prompt');
        }
        case DeviceErrorCodes.UserCanceledInteraction: {
          console.log('user Canceled Biomatic allow prompt Interaction'); // code 2
        }
        default: {
          console.log('some other error ocurred', e);
        }
        return e.code.toString;
      }
    }
  }*/

  public getDeviceSecurityTypeDesc(){
    return this.vault.config.deviceSecurityType.toString();
  }

  async isFaceIdAvailable(): Promise<boolean> {
    var faceIdHardware =  false;
    const hardware = await Device.getAvailableHardware();
    hardware.forEach((biometricType) => {
      if( biometricType.toString().toLowerCase() == 'face'){
        faceIdHardware  = true;
      }
    });
    const biometricsEnabled = await Device.isBiometricsEnabled();
    if( faceIdHardware && biometricsEnabled){
      return true;
    }else{
      return false;
    }    
  }

  async isFingerPrintAvailable(): Promise<boolean> {
    var fingerPrintHardware =  false;
    const biometricStrength = await Device.getBiometricStrengthLevel();
    const hardware = await Device.getAvailableHardware();
    hardware.forEach((biometricType) => {
      if( biometricType.toString().toLowerCase() == 'fingerprint'){
        fingerPrintHardware  = true;
      }
    });
    const biometricsEnabled = await Device.isBiometricsEnabled();
    if( fingerPrintHardware && biometricsEnabled && biometricStrength == BiometricSecurityStrength.Strong){
      return true;
    }else{
      return false;
    }

  }


  async isBiometricsEnabled(){
    var fingerPrintAva :boolean = await this.isFingerPrintAvailable();
    var faceIdAva :boolean = await this.isFaceIdAvailable();
    const sysPassAva = await this.isSystemPasscodeEnabled();
    var platform = this.utilities.getDeviceInfo().PLATFORM;
    if("IOS" == platform.toUpperCase()){
      if(!this.isBiometricsAllowed()){
        faceIdAva = false;
        fingerPrintAva = false;
      }
    }
    if(fingerPrintAva || faceIdAva ||  sysPassAva){
      return true;
    }else{
      return false;
    }
  }

  async isSystemPasscodeEnabled() : Promise<boolean> {
    const hasSystemPasscode = await  Device.isSystemPasscodeSet();
    if (hasSystemPasscode) {
        return true;
    }else{
      return false
    }
  }

  async clearLoginPreferencesLocalStore(){
    await this.localStorge.removeLocalStorageItem(LocalStorage.BIO_AND_PASSCODE_SELECTED);
    await this.localStorge.removeLocalStorageItem(LocalStorage.SYS_PASSCODE_SELECTED);
    await this.localStorge.removeLocalStorageItem(LocalStorage.FINGER_PRINT_SELECTED);
    await this.localStorge.removeLocalStorageItem(LocalStorage.FACE_ID_SELECTED);
  }

  async isBiometricsAllowed(): Promise<boolean>{
    const permission = await Device.isBiometricsAllowed();
    if( permission === BiometricPermissionState.Denied ){
      return false;
    }else{
      return true;
    }
  }
  

  async isDeviceBiometricsChanged(){
    const fingerprintSel  = await this.localStorge.getLocalStorageItemAsync(LocalStorage.FINGER_PRINT_SELECTED) as string;
    const faceIdSel = await this.localStorge.getLocalStorageItemAsync(LocalStorage.FACE_ID_SELECTED) as string;
    //const bioAndPassCodeSel = await this.localStorge.getLocalStorageItemAsync(LocalStorage.BIO_AND_PASSCODE_SELECTED) as string;
    const passCodeSel = await this.localStorge.getLocalStorageItemAsync(LocalStorage.SYS_PASSCODE_SELECTED) as string;
    var fingerPrintAva : boolean = await this.isFingerPrintAvailable();
    var faceIdAva :boolean = await this.isFaceIdAvailable();
    const sysPassAva = await this.isSystemPasscodeEnabled();
    var platform = this.utilities.getDeviceInfo().PLATFORM;
    if("IOS" == platform.toUpperCase()){
      if(!this.isBiometricsAllowed()){
        faceIdAva = false;
        fingerPrintAva = false;
      }
    }
    if( (fingerprintSel && !fingerPrintAva ) ||
        (faceIdSel  && !faceIdAva ) || (passCodeSel  && !sysPassAva) || (faceIdSel && fingerPrintAva)){
          return true;
    }
  }


}
import { Injectable } from "@angular/core";
import { SingleSignOn } from './single-sign-on';
import { Utilities } from "./utilities";
import { SessionData } from "./session-data";
import { Adapter } from "./adapter";
//import { MenuController } from "@ionic/angular";
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class TeladocService {

  constructor(private utilityHandler: Utilities,
    private ssoHandler: SingleSignOn,
    private loadingCtrl: LoadingController,
    private sessionDataHandler: SessionData,
    private adapterHandler: Adapter) {
  }

  openTeladocSDK() {
    let teladocPlugin = (window['cordova'].plugins as any).TelaDocPlugin;
    if(teladocPlugin) {
        this.checkTeladocLogin(teladocPlugin);
      } else {
        this.openTeladocSSO();
     }
  }

  openTeladocSSO() {
    this.ssoHandler.callSSO('teladoc', true);
  }

  async checkTeladocLogin(teladoc) {
    let self = this;
    const loading = await this.loadingCtrl.create({
        message: 'Please wait...'
    });
    loading.present();
    //this.adapterHandler.showLoader();
    let success = function () {
      self.openTeladocDashBoard(teladoc, null);
    }
    let failure = function (message) {
      //self.adapterHandler.hideLoader();
      self.getTeladocAccessToken(teladoc);
    }
    loading.dismiss();
    teladoc.isLoggedIn(success, failure);
  }

  getTeladocAccessToken(teladoc) {
    let params = {};
    params["securityToken"] = this.sessionDataHandler.securityToken;
    params["memberId"] = this.sessionDataHandler.memberProfileData[SessionData.MEMBER_ID];
    params["firstname"] = this.sessionDataHandler.memberProfileData[SessionData.FIRST_NAME];
    params["lastname"] = this.sessionDataHandler.memberProfileData[SessionData.LAST_NAME];
    params["dob"] = this.sessionDataHandler.memberProfileData[SessionData.DOB];
    this.adapterHandler.securedPostRequest(JSON.stringify(params), Adapter.GET_TELADOC_LOGIN_TOKEN, true)
      .then((data: any) => {
        let responseCode = data.statusCode;
        if (responseCode == 200) {
          this.initTeladocSDK(teladoc, data["environment"].toLowerCase());

          let teladocLoginToken = data["loginToken"];
          
          this.openTeladocDashBoard(teladoc, teladocLoginToken);
        } else if (responseCode == 422) {
          let messageType = data.messages[0].messageType;
          let message = data.messages[0].message;
          if (messageType && message) {
            //this.adapterHandler.hideLoader();
            this.utilityHandler.showAlert("Error", "",message, "OK");
          }
        } else {
          this.utilityHandler.serviceUnknownError();
        }
      }, (err) => {
        if (err) {
          this.utilityHandler.serviceError(err);
        }
      });
    }

  initTeladocSDK(teladoc, environment) {
    console.log('initTeladocSDK environment ', environment);
    if(environment === "dev" || environment === "pqa") {
      teladoc.setTestApiKey({}, {});
    } else {
      teladoc.setApiKey({}, {});
    }
  }

  async openTeladocDashBoard(teladoc, loginToken) {
    const loading = await this.loadingCtrl.create({
        message: 'Please wait...'
    });
    loading.present();
    let self = this;
    let success = function() {
      loading.dismiss();
      self.adapterHandler.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], ["MMA_TELADOC_SSO","Teladoc Homepage SDK Clicked"]);
      //self.adapterHandler.hideLoader();
      self.startKeepAlive && self.startKeepAlive();
      self.stopIdleTimer && self.stopIdleTimer();
    }
    let failure = function(errorObj) {
      loading.dismiss();
      //self.adapterHandler.hideLoader();
      let errorJSON = JSON.parse(errorObj);

      if(errorJSON.notifyUser === "1") {
        self.utilityHandler.showAlert("Error", "", errorJSON.errMsg, "OK");
      }
    }
    teladoc.startDashboard(loginToken, success, failure);
  }

  logout() {
    let self = this;
    let teladocPlugin = (window['cordova'].plugins as any).TelaDocPlugin;
    let success = function() {
      console.log("Logged out of Teladoc");
    }
    let failure = function(errorObj) {
      let errorJSON = JSON.parse(errorObj);

      if(errorJSON.notifyUser === "1") {
        this.utilityHandler.showAlert("Error", errorJSON.errMsg);
      }
    }
    self.stopKeepAlive && self.stopKeepAlive();
    self.startIdleTimer && self.startIdleTimer();
    if (teladocPlugin) {
      teladocPlugin.logout(success, failure);
    }
  }

  startKeepAlive: () => void | undefined;

  stopKeepAlive: () => void | undefined;

  stopIdleTimer: () => void | undefined;

  startIdleTimer: () => void | undefined;
}
import { Injectable } from '@angular/core';
//import { AlertController } from 'ionic-angular';
import { AlertController } from '@ionic/angular';
//import { Platform } from 'ionic-angular';
import { Platform } from '@ionic/angular';
//import { StatusBar } from '@ionic-native/status-bar';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
//import { AppVersion } from '@ionic-native/app-version';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
//import { Keyboard } from '@ionic-native/keyboard';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
//import { Device } from '@ionic-native/device';
import { Device } from '@awesome-cordova-plugins/device/ngx';
//import 'rxjs/add/operator/map';
//import { map } from 'rxjs/operators';
//import {LocalStorage} from './local-storage'
import { SessionData } from './session-data';
import {Router} from '@angular/router';

declare var encode_deviceprint: any;
declare var WL;

@Injectable({
  providedIn: 'root',
})
export class Utilities {

  ipAddress = "";

  public static MEMBER_ID = 'MEMBER_ID';
  public static MEMBER_TYPE = 'MEMBER_TYPE';
  public static EMAIL_ID = 'EMAIL_ID';
  public static ACTION_CODE = 'ACTION_CODE';
  public static ACTION_DESCRIPTION = 'ACTION_DESCRIPTION';
  public static PAGE = 'SCREEN_NAME';
  public static USERNAME = 'USERNAME';
  public static THP_PACKAGE_NAME = 'com.tufts.mobilemember';
  public static FREEDOM_PACKAGE_NAME = 'com.freedom.mobilemember';
  public static USFHP_PACKAGE_NAME = 'com.usfhp.mobilemember';
  public static CAREPARTNERS_PACKAGE_NAME = 'com.cpct.mobilemember';
  //public appType:string ;
  //public isBrowser:boolean;
  public appId: any;

  constructor(
    private device: Device,
    private sessionDataHandler: SessionData,
    private statusBar: StatusBar,
    private keyboard: Keyboard,
    private alertCtrl: AlertController,
    private platform: Platform,
    private appVersion: AppVersion,
    private router: Router 
    ) {
    
  }

  previousMonth(month) {
    var date = new Date();
    let temp = new Date(date.getFullYear(), date.getMonth(), 1);
    temp.setMonth(temp.getMonth() + (-month + 1));
    temp.setDate(temp.getDate() - 1);
    if (date.getDate() < temp.getDate()) {
      temp.setDate(date.getDate());
    }

    var date_before = this.converDateToYYYYMMDD(temp) + "T00:00:00.000-0500";
    var dateExtracted = date_before.substring(0, date_before.indexOf('T'));
    dateExtracted = this.convertDateToMMDDYYYY(dateExtracted);
    return dateExtracted;
  }
  /*
  async getAppInfo() {
    if(this.platform.is('hybrid')){
      this.appType = await this.appVersion.getPackageName();
    }
  }*/

  /*
  async setAppType(){
   
    if(! this.isBrowser){
      const packName =  await this.appVersion.getPackageName();
      if( Utilities.USFHP_PACKAGE_NAME == packName ){
        this.appType = "USFHP";
      }else if( Utilities.CAREPARTNERS_PACKAGE_NAME == packName ){
        this.appType = "CAREPARTNERSCT";
      }else{
        this.appType = "THP";
      }
    }else{
      this.appType = this.getBrowserAppType();
    }
  } */

  async getAppType(){
    let appType : string;
    if(! this.isMobileBrowser()){
      const packName =  await this.appVersion.getPackageName();
      if( Utilities.USFHP_PACKAGE_NAME == packName ){
        appType = "USFHP";
      }else if( Utilities.CAREPARTNERS_PACKAGE_NAME == packName ){
        appType = "CAREPARTNERSCT";
      }else{
        appType = "THP";
      }
    }else{
      appType = this.getBrowserAppType();
    }
    return appType;
  }



  getBrowserAppType() {
      let url = window.location.href.toLowerCase();
      if (url.includes('freedom')) {
        return 'FREEDOM';
      } else if (url.includes('usfhp')) {
        return "USFHP";
      } else if (url.includes('carepartnersct')) {
        return 'CAREPARTNERSCT';
      } else {
        return 'THP';
      }
  }

    isMobileBrowser() {  
    //return (this.platform.is('desktop') ||  this.platform.is('mobileweb'));
    this.platform.ready(); 
    if( this.device.platform  == null || this.device.platform == 'browser'){
      return  true;
    }
  }

  /*
  public async showAlert(heading, msg) {
    let alertCSS = this.getAppType().toLowerCase() + ' point32healthsans-text';
    console.log('showAlert ',msg);
    const alert =  await this.alertCtrl.create({
     
      //title: heading,
      header : heading,
      message: msg,
      cssClass: alertCSS,
      //enableBackdropDismiss: false,
      buttons: ['Ok']  
    });
    await alert.present();
  }
  */

  getDevicePrint() {
    return encode_deviceprint();
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  getCurrentDate() {
    return new Date();
  }

  getAuditDate(date) {
    var curDate = this.converDateToYYYYMMDD(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var millis = date.getMilliseconds();
    var auditDt = curDate + " " + hours + ":" + minutes + ":" + seconds + "." + millis;
    return auditDt;
  }
  
  convertDateToMMDDYYYY(date) {
    var dateData = date.split('-');
    var year = dateData[0];
    var month = dateData[1];
    var day = dateData[2];
    var dateFormated = month + "/" + day + "/" + year;
    return dateFormated;
  }

  converDateToYYYYMMDD(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    var dateFormated = yyyy + '-' + mm + '-' + dd;
    return dateFormated;
  }

  async createAnalyticsJson(key, value) {
    var dataDictionary = {};
    for (var i = 0; i < key.length; i++) {
      if (key[i] == Utilities.ACTION_CODE) {
        dataDictionary[key[i]] = value[i];
      }
    }

    dataDictionary[Utilities.MEMBER_ID] = "";
    if (this.sessionDataHandler.memberId) {
      dataDictionary[Utilities.MEMBER_ID] = this.sessionDataHandler.memberId;
    }

    dataDictionary[Utilities.EMAIL_ID] = "";
    if (this.sessionDataHandler.emailId) {
      dataDictionary[Utilities.EMAIL_ID] = this.sessionDataHandler.emailId;
    }

    dataDictionary[Utilities.MEMBER_TYPE] = "";
    if (this.sessionDataHandler.memberProfileData) {
      dataDictionary[Utilities.MEMBER_TYPE] = this.sessionDataHandler.memberProfileData[SessionData.MEMBER_TYPE];
    }

    var thp_mobile_app = {};
    for (var j = 0; j < key.length; j++) {
      if (key[j] != Utilities.ACTION_CODE) {
        thp_mobile_app[key[j]] = value[j];
      }
    }
    var thp_device_info = {
      "MODEL": this.device.model,
      "PLATFORM": this.device.platform,
      "VERSION": this.device.version
    };

    dataDictionary["DEVICE"] = thp_device_info;
    dataDictionary["APP_TYPE"] = await this.getAppType();
    dataDictionary["SESSION_ID"] = this.sessionDataHandler.smSessionToken;
    dataDictionary["ACTION_DATE"] = this.getAuditDate(new Date());
    dataDictionary["THP_MOBILE_APP"] = thp_mobile_app;
    return JSON.stringify(dataDictionary);
  }

  getDeviceInfo(){
    var thp_device_info = {
      "MODEL": this.device.model,
      "PLATFORM": this.device.platform,
      "VERSION": this.device.version
    };
    return thp_device_info;

  }

  sendLogForAnalytics(data) {
    if (!this.isMobileBrowser()) {
      WL.Analytics.log(data);
      WL.Analytics.send();
    }
  }


  memberIDFormatting(memberID) {
    if (memberID && !isNaN(memberID.slice(0, 1)) && !isNaN(memberID.charAt(4))) {
      var formattedMemberID = memberID.replace(/\s/g, '');
      if (formattedMemberID.length <= 11) {
        return formattedMemberID.slice(0, -2) + "   " + formattedMemberID.slice(-2, formattedMemberID.length);
      } else {
        return memberID
      }
    } else {
      return memberID
    }
  }

  getFormattedComments(comments) {
    var formattedComments = "";
    var note: string = comments;
    if (note.indexOf("href='/thp/myportal") > -1) {
      var words = note.match(/("[^"]+"|[^"\s]+)/g);
      for (var i = 0; i < words.length; i++) {
        note = words[i];
        var output1 = note.replace(/javascript:window.open\('/g, "");
        var output2 = output1.replace(/\'.*\"/g, "\"");
        formattedComments = formattedComments + " " + output2;
      }
      return formattedComments;
    }
    else {
      return comments;
    }
  }

  getFormattedNotes(notesList) {
    var formattedNotes = [];
    for (var i = 0; i < notesList.length; i++) {
      var finalString = "";
      var note: string = notesList[i];
      if (note.indexOf("href='/thp/myportal") > -1) {
        var words = note.match(/("[^"]+"|[^"\s]+)/g);
        for (var j = 0; j < words.length; j++) {
          note = words[j];
          var output1 = note.replace(/javascript:window.open\('/g, "");
          var output2 = output1.replace(/\'.*\"/g, "\"");
          finalString = finalString + " " + output2;
        }
        formattedNotes.push(finalString);
      }
    }
    return formattedNotes;
  }

  getUUIDNumber() {
    return this.device.uuid; 
  }

  getOsVersion() : string {
    return this.device.version;
  }

  async setDarkStatusBar() {
    

    if (this.platform.is('ios') && !this.isMobileBrowser()) {
      this.statusBar.styleLightContent();
      this.statusBar.overlaysWebView(false);
      if(await this.getAppType() == 'FREEDOM'){
        this.statusBar.backgroundColorByHexString('#355864');
      } else {
        this.statusBar.backgroundColorByHexString('#0367ac');
      }
    }
  }

  setLightStatusBar() {
    if (this.platform.is('ios') && !this.isMobileBrowser()) {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#ffffff');
    }
  }

  closeKeyboard(keyCode) {
    if (keyCode == 13) {
      this.keyboard.hide();
    }
  }


  getMembersPackageName(url: string) {
    if (url.toLowerCase().includes('freedom')) {
      return Utilities.FREEDOM_PACKAGE_NAME;
    } else if (url.toLowerCase().includes('usfhp')) {
      return Utilities.USFHP_PACKAGE_NAME;
    } else {
      return Utilities.THP_PACKAGE_NAME;
    }
  }

  getMembersPackageNameByType(appType: string) {
    if (appType.toLowerCase().includes('usfhp')) {
      return Utilities.USFHP_PACKAGE_NAME;
    } else {
      return Utilities.THP_PACKAGE_NAME;
    }
  }

  /*
  async getPackageName() {
    // TODO: use appVersion
    
    switch(this.appType) {
      case 'FREEDOM': {
        return Utilities.FREEDOM_PACKAGE_NAME;
      }
      case 'USFHP': {
        return Utilities.USFHP_PACKAGE_NAME;
      }
      case 'CAREPARTNERSCT': {
        return Utilities.CAREPARTNERS_PACKAGE_NAME;
      }
      default: {
        return Utilities.THP_PACKAGE_NAME;
      }
    }
  }*/
  

  /*
  async openLinkWithWarning(url: string, onOk?: () => Promise<void>) {
    const alert = await this.alertCtrl.create({
      header: 'You are leaving the app',
      message: "You will be taken to our partner's website via your mobile browser.", 
      //enableBackdropDismiss: false, 
      cssClass: 'ssoNotify',
      mode: 'md',
      buttons: [{
        text: 'CANCEL',
        role: 'cancel',
        handler: () => {
          return;
        }
      },{
        text: 'OK',
        cssClass: 'btn-ok',
        handler: () => {
          if (onOk) {
            onOk().then(() => this.openLink(url));
          } else {
            this.openLink(url);
          }
        }
      }]
    })
    await alert.present;
  } */

  async openLinkWithWarning(url: string, onOk?: () => Promise<void>) {
    const alert = await this.alertCtrl.create({
      header: 'You are leaving the app',
      message: "You will be taken to our partner's website via your mobile browser.", 
      cssClass: 'ssoNotify',
      buttons: [
        {
          text: 'CANCEL',
          role: 'cancel',
          handler: () => { return; }
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => { this.openLink(url) }
        }
      ]
    });
    await alert.present();
    //const { role } = await alert.onDidDismiss();
    //this.roleMessage = `Dismissed with role: ${role}`;
  }


  openLinkViaLocation(url: string) {
    window.location.href = url;
  }

  openLink(url: string) {
    if (!this.isMobileBrowser()) {
      this.openLinkViaLocation(url);
    }
    let link = document.createElement('a')
    link.id = "external";
    link.href = url;
    link.target = '_blank';
    try {
      if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
        document.body.appendChild(link);
      }
    } catch(err) {
      console.log("Error:  " + err);
      link.click();
    }
    link.click();
  }

  compareJSONObject(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  public async showAlert(header: string, sub:string, messge:string, buttonLabel:string){

    const alert = await this.alertCtrl.create({
      cssClass:  'popalert',
      header: header,
      subHeader: sub,
      message: messge,
      buttons: [buttonLabel]
    });
    await alert.present();
  }
  
  public async serviceUnknownError(){
    var unknownError = { "message": "Unknown Error", "status": 500};
    this.serviceError(unknownError);

  }
  public async serviceError(err:any){
    if(err && err.status){
      if( err.status == 400){
        if((err.errorCode == SessionData.MFP_APPLICATION_DOES_NOT_EXIST)){
          this.showAlert("Error", "", "We have encountered an error that will not resolve on its own (E1).  Please call the number on the back of your member ID card, so that we can assist.", "OK");
        }else if((err.errorCode == SessionData.MFP_SECURITY_CHECK_NOT_FOUND)  ){
          this.showAlert("Error", "", "We have encountered an error that will not resolve on its own (E2).  Please call the number on the back of your member ID card, so that we can assist.”", "OK");
        }
      }else if( err.status == 422){
        this.showAlert("Error", "", "We have encountered an error that will not resolve on its own (E3).  Please call the number on the back of your member ID card, so that we can assist.”", "OK");
      }else{
        this.showAlert("Error", "", "Services are temporarily unavailable (E4). If the issue does not resolve itself within 30 minutes, please contact us at the number on your member ID card.", "OK");
      }
    }  
  }

  

  public async showAlertNavLoginPage(header: string,  messge:string){
    
    
    const alert = await this.alertCtrl.create({
      cssClass: 'popalert',
      header: header, 
      message: messge,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => { this.router.navigate(['/userlogin'], { skipLocationChange: true}) }
        }
      ]
    });
    await alert.present();
  }

  isNewUserLoggedIn(currentUserEmailId:string, previousUserId:string){
    //var previousUser =  await this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.ALTERNATE_LOGIN_EMAIL_ID) as string;
    if (previousUserId === null || previousUserId === undefined || previousUserId.length <= 0 || 
      previousUserId == '' || (previousUserId.toUpperCase() != currentUserEmailId.toUpperCase()) ) {
        return true;
    }
    
  }
  /*
  async checkAlternativeLogin(){
    var bioSelect  =  await this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.USER_SElECT_BIO_OR_SYS_PASSCODE); 
    var bioIgnore  =   await this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.USER_IGNROE_BIO_AND_SYS_PASSCODE);
    if( (bioSelect == null || bioSelect == 'false') && (bioIgnore == null || bioIgnore == false)){
      return true;
    }
    return false;     
  }*/
   
}

<ion-menu side="end"  menuId = "menuPop" swipeGesture ="false" type="overlay" contentId="main-content">
    <ion-content class="moreWrapper">
      <div class="pophoverHeading">
        <ion-grid> 
          <ion-row class="ion-align-items-end">
            <ion-col class ="ion-no-padding"  size="10">
            </ion-col>
            <ion-col size="2" class="ion-align-self-end">
              <ion-buttons>  
                <ion-button slot = "end" lines="none"  (click)="closeMenu()" >
                  <ion-icon  style = "margin: 0px 0px 0px 0px;padding: 0px;"  slot="icon-only" name="close"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>  
        </ion-grid>
        <span  *ngIf = "this.sessionDataHandler.memberProfileData != null">
            <div class="initials">{{ this.sessionDataHandler.memberProfileData['initials'] }}</div>
            <h1>{{ this.sessionDataHandler.memberProfileData['fullName'] }}</h1>
            <h2>{{  "Member # " + this.sessionDataHandler.memberProfileData['memberId'] }}</h2>
            <h2>{{ this.sessionDataHandler.memberProfileData['planName'] }}</h2>
        </span>
      </div>
      <div>
        <div class="listHeader">General</div>
        <ion-list>
          <ion-item type ="full" fill = "clear" lines="none" class = "more-pop-icon-button" button (click)="openHomePage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/homeicon.svg" size=large></ion-icon>
          <ion-label>
            Home
          </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear" lines="none" class = "more-pop-icon-button" button (click)="goToReferrals()">
              <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/referrals.svg" size=large></ion-icon>
            <ion-label>
              <span  *ngIf="!isTMPMember()">Referrals</span>
              <span  *ngIf="isTMPMember()">PCP Referrals</span>
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"  lines="none" class = "more-pop-icon-button" button (click)="gotoNotificationsPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/notifications.svg" size=large></ion-icon>
            <ion-label>
              <span *ngIf="!isTMPMember()">Authorizations & <br/> Notifications</span>
              <span *ngIf="isTMPMember()">Coverage Decisions</span>
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" class = "more-pop-icon-button" button (click)="goToProviderSearch('providerSearch')">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/findadocicon.svg" size=large></ion-icon>
            <ion-label>
              Find A Doctor
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showPharmacy()" class = "more-pop-icon-button" button (click)="goToCommPharmacy()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/pillicon.svg" size=large></ion-icon>
            <ion-label>
              Pharmacy
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showUSFHPPharmacy()" class = "more-pop-icon-button" button (click)="goToUsfhpPharmacyPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/pillicon.svg" size=large></ion-icon>
            <ion-label>
              Pharmacy
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showTMPPharmacy()" class = "more-pop-icon-button" button (click)="goToTmpPharmacyPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/pillicon.svg" size=large></ion-icon>
            <ion-label>
              Drug Coverage
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showTMPPharmacyLitePage()" class = "more-pop-icon-button" button (click)="goToTmpLitePharmacyPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/pillicon.svg" size=large></ion-icon>
            <ion-label>
              Drug Coverage
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showWellness()" class = "more-pop-icon-button" button (click)="goToWellnessSSO()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/wellnessicon.svg" size=large></ion-icon>
            <ion-label>
              Health & Wellness
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showTeladoc()" class = "more-pop-icon-button" button (click)="goToTeladoc()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/teladocicon.svg" size=large></ion-icon>
            <ion-label>
              Access Telehealth by <br/>Teladoc
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"  lines="none"  *ngIf="showOtc()" class = "more-pop-icon-button" button (click)="goToOtc()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/walleticon.svg" size=large></ion-icon>
            <ion-label>
              Over-the-Counter Bonus Options
            </ion-label>
          </ion-item>


        </ion-list>
        <div class="listHeader" *ngIf="showSop() || showTaxForm() || showHealthEquity() || showBendFinancial() || showAeob()">Financial</div>
        <ion-list>
          

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showSop()" class = "more-pop-icon-button" button (click)="goToSop()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/sopicon.svg" size=large></ion-icon>
            <ion-label>
              Claims Summary of Payment
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showAeob()" class = "more-pop-icon-button" button (click)="goToAeob()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/aeobicon.svg" size=large></ion-icon>
            <ion-label>
              Advance Explanation of Benefits
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showTaxForm()" class = "more-pop-icon-button" button (click)="goToTaxForms()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/taxformsicon.svg" size=large></ion-icon>
            <ion-label>
              Tax Forms
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showHealthEquity()" class = "more-pop-icon-button" button (click)="goToHealthEquity()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/pigicon.svg" size= "large"></ion-icon>
            <ion-label>
              HealthEquity Account
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" *ngIf="showBendFinancial()" class = "more-pop-icon-button" button (click)="goToBendFinancial()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/pigicon.svg" size= "large"></ion-icon>
            <ion-label>
              Bend Financial Account
            </ion-label>
          </ion-item>
      

        </ion-list>
        <div class="listHeader">Support</div>
        <ion-list>
          
          <ion-item type ="full" fill = "clear"   lines="none"  class = "more-pop-icon-button" button (click)="gotoAboutThpPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/abouticon.svg" size= "large"></ion-icon>
            <ion-label>
              About
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none" class = "more-pop-icon-button" button (click)="openContactUsPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/contactusicon.svg" size= "large"></ion-icon>
            <ion-label>
              Contact Us
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"   lines="none"  class = "more-pop-icon-button" button (click)="openLegalInfoPage()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/legalinfoicon.svg" size= "large"></ion-icon>
            <ion-label>
              Legal Information
            </ion-label>
          </ion-item>
 
        </ion-list>        
        <div class="listHeader" >Forms</div>
        <ion-list>
          <ion-item type ="full" fill = "clear"   lines="none"  class = "more-pop-icon-button" button (click)="openAuthForm()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/referrals.svg" size= "large"></ion-icon>
            <ion-label>
              Authorization to Disclose Protected Health Information 
            </ion-label>
          </ion-item>

          <ion-item type ="full" fill = "clear"  lines="none"  class = "more-pop-icon-button" button (click)="openDesigForm()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/referrals.svg" size= "large"></ion-icon>
            <ion-label>
              Designated Representative Form
            </ion-label>
          </ion-item>
         
          <ion-item type ="full" fill = "clear" *ngIf="showCob()"   lines="none"  class = "more-pop-icon-button" button (click)="goToCob()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/referrals.svg" size= "large"></ion-icon>
            <ion-label>
              Coordination of Benefits
            </ion-label>
          </ion-item>

        </ion-list>
        <div class="listHeader">App & Account Settings</div>
        <ion-list>

          <ion-item type ="full" fill = "clear"   lines="none" class = "more-pop-icon-button" button (click)="goToManageMyAccount()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/personicon.svg" size= "large"></ion-icon>
            <ion-label>
              Manage My Account
            </ion-label>
          </ion-item>
         
        </ion-list>
        <ion-list>
          
          <ion-item type ="full" fill = "clear"   lines="none" class = "more-pop-icon-button" button (click)="userLogOut()">
            <ion-icon  style = "margin: 5px 10px 5px 0px;" src="/assets/icons/logouticon.svg" size= "large"></ion-icon>
            <ion-label>
              Log Out
            </ion-label>
          </ion-item>
         
        </ion-list>
      </div>
    </ion-content>
</ion-menu>


import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Market } from '@ionic-native/market/ngx';
//import { Utilities } from "./utilities";
import { LocalStorage } from './local-storage';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

@Injectable({
  providedIn: 'root',
})
export class WrongApp {
  constructor(
        //private utilities: Utilities, 
        //private platform: Platform,
        private market: Market,
        private localStorageHandler: LocalStorage,
        private alertCtrl: AlertController,
        private app: AppVersion,
       
        ) {

    }

    /*

    public showWrongAppAlert(msg: string, mobileWebUrl: string) {
        
        let appType:string;
        this.utilities.getAppType().then(async value => {
          appType = value;
          //let appType = this.utilities.appType;
          let appOption = {
            text: 'Download the App',
            cssClass: 'alert-button-text',
            handler: () => {
              let packageName = this.utilities.getMembersPackageName(mobileWebUrl);
              console.log('pack ',packageName);
              if(this.platform.is('ios')) {
                if (this.utilities.isMobileBrowser()) {
                  this.getAppleAppId(packageName).then((appId: string) => {
                    console.log('appId name ', appId);
                    this.utilities.openLink('itms-apps://apps.apple.com/app/apple-store/id' + appId + '?mt=8');
                  });
                } else {
                  if("USFHP" ==  appType){
                    this.market.open('https://apps.apple.com/us/app/usfhp-of-southern-new-england/id1474986277?mt=8');
                    
                  }else {
                    this.market.open('https://apps.apple.com/us/app/tufts-health-plan/id1289408406?mt=8');
                  }
                 
                }
              } else if (this.platform.is('android')) {
                if (this.utilities.isMobileBrowser()) {
                  this.utilities.openLink('https://play.google.com/store/apps/details?id=' + packageName);
                } else {
                  this.market.open(packageName);
                }
              }
            }
          };
          let mobileWebOption = {
              text: 'Redirect to Mobile Web',
              cssClass: 'alert-button-text',
              handler: () => {
                this.utilities.openLink(mobileWebUrl);
              }
          };
          let closeButton = {
            text: 'Close',
            cssClass: 'alert-button-text',
            handler: () => {
            }
          };
          let buttonsArray = [];
          if (appType == 'THP' || appType == 'USFHP') {
            buttonsArray.push(appOption);
          }
          if (mobileWebUrl && mobileWebUrl.length > 0) {
            buttonsArray.push(mobileWebOption);
          }
          let branding = appType.toLowerCase() + ' point32healthsans-text';
          buttonsArray.push(closeButton);
          let alert = await this.alertCtrl.create({
            //title: 'Wrong App',
            message: msg,
            cssClass: branding,
            //enableBackdropDismiss: false,
            buttons: buttonsArray
          });
          await alert.present();
        }).catch(err => {
        });
        
    } */

    async forceDownloadApp(){
     
      const link = await this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.APP_STORE_LINK) as string;
      let appOption = {
        text: 'Download the App',
        cssClass: 'alert-button-text',
        handler: () => {
          this.market.open(link); 
        }
      };
      let buttonsArray = [];
      buttonsArray.push(appOption);
      //let branding = appType.toLowerCase() + ' point32healthsans-text';
      let alert = await this.alertCtrl.create({
        //title: 'Wrong App',
        header: "Important App Update",
        message: "Please update your app to the latest version to continue using it",
        backdropDismiss: false,
        buttons: buttonsArray
      });
      await alert.present();
    }

    async checkForceAppDownload(){
      const currentVersionOnStore = await this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.APP_VERSION_IN_STORE) as string; 
      this.app.getVersionNumber().then((s) => {
        var currentVersionOnApp: string = s;
        if( currentVersionOnApp  && currentVersionOnStore){
          const currentV = currentVersionOnApp.split('.');
          const storeV =  currentVersionOnStore.split('.');
          if( currentV[0] < storeV[0]){
            this.forceDownloadApp();
          }
        }
      })
    }

    async appVersionAvailable(){
          const link = await this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.APP_STORE_LINK) as string;
          const alert = await this.alertCtrl.create({
            header: 'App update available',
            subHeader: "There's a new version available, would you like to get it now?",
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => { 
                 
                },
              },
              {
                text: 'Download',
                role: 'confirm',
                handler: () => {
                  this.market.open(link); 
                },
              },
            ]
          });
          await alert.present();
        
    }

}
import { Injectable } from '@angular/core';
//import { Events, Platform, App} from 'ionic-angular';
import { Platform } from '@ionic/angular';
//import 'rxjs/add/operator/map';
//import { map } from 'rxjs/operators';

//import { UserLoginPage } from '../pages/user-login-page/user-login';
import { Member } from '../domain/user/member';
import {RelD} from '../domain/RelDSogi/RelD';

declare var WL;
declare var WLResourceRequest;

@Injectable({
  providedIn: 'root',
})
export class SessionData {

  public static DEVICE_OS_ANDROID = "android";
  public static DEVICE_OS_IOS = "ios";

  //Member profile Data
  public static MEMBER_ID = "memberId";
  public static SUBSCRIBER_ID = "subscriberId";
  public static MEMBER_TYPE = "memberType";
  public static HAS_DEPENDENTS = "hasDependents";
  public static HAS_MULTIPLE_IDS = "hasMultipleIds";
  public static PROVIDER_SEARCH_URL = "providerSearchUrl";
  public static CONTACT_WIDGET_URL = "contactWidgetUrl";
  public static CAREMARK_URL = "caremarkUrl";
  public static CAREMARK_PHARMACY_URL = "caremarkPharmacyUrl";

  public static OPTUM_URL = "optumUrl";
  public static OPTUM_MEDICATION_URL = "optumMedicationUrl";
  public static OPTUM_PHARMACY_GO_LIVE = "optumPharmacyGoLive";
  public static MEDICARE_DRUG_COV_GO_LIVE = "medicareDrugCovGoLive";


  public static TERMED_MEMBER = "termedMember";
  public static FULL_NAME = "fullName";
  public static FIRST_NAME = "firstName";
  public static LAST_NAME = "lastName";
  public static PLAN_NAME = "planName";
  public static FORMATTED_MEMBER_ID = "formattedMemberId";
  public static HAS_ACTIVE_COVERAGE = "hasActiveCoverage";
  public static IS_MINOR = "minor";
  public static SSO_REDIRECT_URL = "ssoRedirect";
  public static HAS_SOP = "hasSop";
  public static PCP_CHANGED_IN_LAST_90_DAYS = "pcpChangedInLast90Days";
  public static HAS_TAX_DOCS = "hasTaxDocs";
  public static INITIALS = "initials";
  public static IS_MERGED = 'merged';
  public static UNIQUE_ID = "uniqueId";
  public static MOBILE_NUMBER = "mobilePhone";
  public static HOME_PHONE = "homePhone";
  public static SHOW_HOME_PHONE = "showHomePhone";
  public static MYWIRE_PHONE = "myWirePhone";
  public static SHOW_MYWIRE = "showMyWire";
  public static ADDRESS_LINE_1 = "mailingAddressLine1";
  public static ADDRESS_LINE_2 = "mailingAddressLine2";
  public static PCP = "currentPcp";
  public static SECURITY_QUESTIONS = "securityQuestionsMap";
  public static SECURITY_ANSWERS = "securityAnswersMap";
  public static USER_ID = "userId";
  public static BUS_TYP_IND = "businessTypeIndicator";
  public static HAS_COMM_PHARMACY = "hasCommPharmacy";
  public static HAS_USFHP_GROUP = "hasUSFHPGroup";
  public static HAS_TMP_GROUP = "hasTMPGroup";
  public static HAS_TMP_PHARMACY = "hasTMPPharmacy";
  public static HAS_SCO_GROUP = "hasSCOGroup";
  public static HAS_TELADOC_GROUP = "hasTeladocGroup";
  public static HAS_HEALTH_EQUITY_GROUP = "hasHealthEquityGroup";
  public static HAS_TOGETHER_GROUP = "hasTogetherGroup";
  public static HAS_DIRECT_GROUP = "hasDirectGroup";
  public static MEMBER_TYPE_COMM = "COMM";
  public static MEMBER_TYPE_TMP = "MEDPREF";
  public static MEMBER_TYPE_USFHP = "USFHP";
  public static MEMBER_TYPE_FREEDOM = "FREEDOM";
  public static HAS_COMM_ACTIVE = "hasCommActive";
  public static WELLNESS_URL = "wellnessUrl";
  public static PRODUCT_CODE = "product";
  public static PLAN_CODE = "planCode";
  public static SOURCE_SYSTEM_ID = "sourceSysId";
  public static BUSINESS_LINE_KEY = "businessLineKey";
  public static GROUP_ID = "groupIds";
  public static EMAIL_ID = "email";
  public static EOB_PREF = "eobPreference";
  public static PLAN_DOC_PREF = "planDocPreference";
  public static MARKETING_PREF = "formattedMarketingPreference";
  public static MARKET_PREF = "marketingPreference";
  public static LOGOUT_USER = "logOut";
  public static SUBSCRIBER = "subscriber";
  public static HAS_FUTURE_COVERAGE = "hasFutureCoverage";
  public static EFFECTIVE_DATE = "effectiveDate";
  public static PCP_REQUIRED = "pcpRequired";
  public static SECURITY_QUESTIONS_LIST = "securityQuestionsList";
  public static TMP_MED_SUPP_TYPE = "medSuppType";
  public static TMP_MED_SUPP_MEMBER = "medSuppMember";
  public static SHOW_EDIT_HELP = "showEditHelp";
  public static TELADOC_URL = "teladocUrl";
  public static MISSING_SSN = "missingSSN";
  public static REL_INFO = "relData";
  public static SOGI_INFO = "sogiData";
  public static HEALTH_EQUITY_URL = "healthEquityUrl";
  public static FUTURE_EFFECTIVE_DATE = "futureEffectiveDate";
  public static THPP_DIRECT_PRODUCT_CODES = ["NS", "SB"];
  public static THPP_MA_MEMBER = "thppMaMember";
  public static THPP_RI_MEMBER = "thppRiMember";
  public static MEMBER_TYPE_THPP_MA = "THPP_MA";
  public static MEMBER_TYPE_THPP_RI = "THPP_RI";
  public static RX_SHOPPER_URL = "prescriptionUrl";
  public static HAS_RX_SHOPPER_GROUP = "hasRxShopperGroup";
  public static RES_ADDRESS_LINE_1 = "resAddressLine1";
  public static DOB = "dob";
  public static HAS_TAX_FORM_GROUP = "hasTaxFormGroup";
  public static HAS_BEND_FINANCIAL_GROUP = "hasBendFinancialGroup";
  public static BEND_FINANCIAL_URL = 'bendFinancialUrl';
  public static HAS_AEOB_GROUP = 'hasAeobGroup';
  public static LIMEADE_URL = 'limeadeUrl';
  public static IS_LIMEADE_LIVE = 'limeadeLive';
  public static GROUPS = 'groups';
  public static OTC_TMP_MA_URL = 'otcTmpMaUrl';
  public static MFP_APPLICATION_DOES_NOT_EXIST :string = "APPLICATION_DOES_NOT_EXIST";
  public static MFP_SECURITY_CHECK_NOT_FOUND :string = "SECURITY_CHECK_NOT_FOUND";
  

  //Used for user idle timeout
  lastPing?: Date = null;

  //used for login-registration session details
  identityToken: string = null;
  memberId: string = null;
  dateOfBirthString: string = null;
  regQuizQuestion: any;
  userId: string;
  emailId: string;
  identityQuestion: any;
  password: string;
  memberType: string;
  merged: boolean;
  groups: string[];

  //For analyze API
  transactionId: string;
  sessionId: string;
  deviceTokenCookie: string;

  //App session details
  deviceOS: string;
  memberProfileData: any;
  membersOnPlan: any;
  benefitData: any = "";
  smSessionToken: any = "";
  securityToken: any = "";
  shouldDisplayLoginPreferences = true;
  impersonationCookie: string;
  isImpersonating: boolean;
    
  relInfo: any;
  sogiInfo: any;
  relDInfo: RelD;

  relInfoSelected: { [key: string]: any } = {}; 
  sogiInfoSelected: { [key: string]: any } = {}; 
  relDInfoSelected: { [key: string]: any } = {}; 

  showRelAtt:boolean = false;
  afterSurvey : boolean = false;
  mmeRelUpdateFlag:  boolean = false;
  showRelCancel:boolean = false
  relToggleValue=false;
  optOutFlag: string;

  //logout Domain
  logoutURLDomain: string;

  //buildversion update from jenkin
  buildversion: string = "606";

  memberContactDetails: any = "";
  memberDetailsChannel: any = "";

  taxFormFAQ: any;
  member: Member;
  isSecondaryUser: boolean;
  sourceSysId: string;
  hideEdeliveryPrefs: boolean = false;
  showAeobLanguage: boolean = false;
  lastAuthFailed: boolean = false;
  userLogout:boolean = false;
  userLoggedIn:boolean = false;
  isDependentMemReg :boolean =  false;
  appApprovalTestAct:string ="jasonexample@abc123xyz.com";
 
 

  constructor(//public events: Events,
    private platform: Platform
    //private app: App
    ) {

    if (this.platform.is('ios')) {
      this.deviceOS = SessionData.DEVICE_OS_IOS;
    }
    else if (this.platform.is('android')) {
      this.deviceOS = SessionData.DEVICE_OS_ANDROID;
    }
  }

  clearSessionData() {
    this.identityToken = null;
    this.memberId = null;
    this.dateOfBirthString = null;
    this.regQuizQuestion = null;
    this.smSessionToken = "";
    this.userId = "";
    this.emailId = "";
    this.identityQuestion = null;
    this.password = "";
    this.memberType = "";

    //For analyze API
    this.transactionId = "";
    this.sessionId = "";
    this.deviceTokenCookie = "";

    //App session details
    this.memberProfileData = null;
    this.membersOnPlan = null;
    this.benefitData = "";
      
    //For REL
    this.afterSurvey = false;
    this.sogiInfo = null;
    this.relInfo = null;
    this.relDInfo = null;

    this.relInfoSelected = {}; 
    this.sogiInfoSelected = {}; 
    this.relDInfoSelected = {}; 

    this. relInfoSelected = {}; 
    this.relToggleValue=false;
    this.optOutFlag = null;
    this.sogiInfoSelected = {};
    this.isDependentMemReg =  false;
    this.mmeRelUpdateFlag = false;
    this.showRelCancel = false;
    this.showRelAtt = false;
    //clear event subscription
    //this.unsubscribeEvents();
  }

  


}

import { Component,Input } from '@angular/core';
import {OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'pdf-view',
  templateUrl: 'pdfviewer.html',
  styleUrls: ['pdfviewer.scss']
})
export class PdfViewerPage implements OnInit{

  @Input() fileName: string;
  @Input() pdfUrl: string;
  @Input() showBorder: boolean;

  constructor(private controler : ModalController) {
    //this.pdfUrl = params.get('pdfUrl');
    //this.fileName = params.get('fileName') ? params.get('fileName') : "Tufts Health Plan PDF";
    if(this.fileName == null){
      this.fileName = 'Tufts Health Plan PDF';
    }
  }
  ngOnInit() {
    
  }
  
  close() {
    this.controler.dismiss();
  }

  getPdfUrl() {
    return this.pdfUrl;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes ,PreloadAllModules} from '@angular/router';



const routes: Routes = [
  
  
  {
    path: 'registration',
    loadChildren: () => import('./pages/user-registration/user-registration.module').then(m => m.UserRegistrationPageModule)
  },
  {
    path: 'regconfirm',
    loadChildren: () => import('./pages/reg-confirm/reg-cofirm.module').then(m => m.RegConfirmPageModule)
  },
  {
    path: 'userlogin',
    loadChildren: () => import('./pages/user-login/user-login.module').then(m => m.UserLoginPageModule)
  },
  {
    path: 'forgotemail',
    loadChildren: () => import('./pages/forgot-email/forgot-email.module').then(m => m.ForgotEmailPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'memberidhelp',
    loadChildren: () => import('./pages/member-id-help-img/member-id-help-img.module').then(m => m.MemberIdHelpImgPageModule)
  },
  {
    path: 'termsofuse',
    loadChildren: () => import('./pages/terms-of-use/tems-of-use.module').then(m => m.TermsOfUsePageModule)
  },
  {
    path: 'legalsecurity',
    loadChildren: () => import('./pages/legal-security/legal-security.module').then(m => m.LegalSecurityPageModule)
  },
  {
    path: 'termsandconditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.moudle').then(m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'home/reflandingpage',
    loadChildren: () => import('./pages/referrals-landing/referrals-landing.module').then(m => m.ReferralsLandingPageModule)
  },
  {
    path: 'home/reflandingdetails',
    loadChildren: () => import('./pages/referrals-details/referrals-details.module').then(m => m.ReferralsDetailsPageModule)
  },
  {
    path: 'home/mergedmsg',
    loadChildren: () => import('./pages/merged-message/merged-message.module').then(m => m.MergedMessagePageModule)
  },
  
  {
    path: 'home/claimdetails',
    loadChildren: () => import('./pages/claim-details/claims-details.module').then(m => m.ClaimDetailsPageModule)
  },
  {
    path: 'home/sendmessage',
    loadChildren: () => import('./pages/send-message/send-message.module').then(m => m.SendMessagePageModule)
  },
  {
    path: 'home/schedulecallback',
    loadChildren: () => import('./pages/schedule-callback/schedule-callback.module').then(m => m.ScheduleCallbackPageModule)
  },
  {
    path: 'home/contactus',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
  },
  {
    path: 'home/benefitscategory',
    loadChildren: () => import('./pages/benefits-category/benefits-category.module').then(m => m.BenefitsCategoryPageModule)
  },
  {
    path: 'home/benefitssubparent',
    loadChildren: () => import('./pages/benefits-sub-parent/benefits-sub-parent.module').then(m => m.BenefitsSubParentPageModule)
  },
  
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/benefitsminmax',
    loadChildren: () => import('./pages/benefits-min-max/benefits-min-max-.module').then(m => m.BenefitsMinMaxPageModule)
  },
  
  {
    path: 'home/changeloginpreference',
    loadChildren: () => import('./pages/change-login-preference/change-login-preference.module').then(m => m.ChangeloginPreferencesPageModule)
  },

  {
    path: 'home/advexpbenefits',
    loadChildren: () => import('./pages/advance-explanation-of-benefits/advance-explanation-of-benefits.module').then(m => m.AdvanceExplanationOfBenefitsPageModule)
  },
  {
    path: 'home/about',
    loadChildren: () => import('./pages/about-thp/about-thp.module').then(m => m.AboutThpPageModule)
  },
  {
    path: 'home/dedhelptext',
    loadChildren: () => import('./pages/ded-help-text/deb-help-text.module').then(m => m.DedHelpTextPageModule)
  },
  {
    path: 'home/drugcoverage',
    loadChildren: () => import('./pages/drug-coverage/drug-coverage.module').then(m => m.DrugCoveragePageModule)
  },
  {
    path: 'home/drugcoveragetmplite',
    loadChildren: () => import('./pages/drug-coverage-tmp-lite/drug-coverage-tmp-lite.module').then(m => m.DrugCoverageTmpLitePageModule)
  },
  {
    path: 'home/edeliverypreferences',
    loadChildren: () => import('./pages/edeliverypreferences/edeliverypreferences.module').then(m => m.EdeliverypreferencesPageModule)
  },
  {
    path: 'home/editmyaccount',
    loadChildren: () => import('./pages/edit-my-account-details/edit-my-account-details.module').then(m => m.EditMyAccountDetailsPageModule)
  },
  {
    path: 'home/editsecurityquestion',
    loadChildren: () => import('./pages/edit-security-question/edit-security-question.module').then(m => m.EditSecurityQuestionPageModule)
  },
  
  {
    path: 'home/idcardfullview',
    loadChildren: () => import('./pages/id-card-full-view/id-card-full-view.module').then(m => m.IdCardFullViewPageModule)
  },
  
  {
    path: 'home/legalinformation',
    loadChildren: () => import('./pages/legal-information/legal-information.module').then(m => m.LegalInformationPageModule)
  },
  {
    path: 'home/legalsecurity',
    loadChildren: () => import('./pages/legal-security/legal-security.module').then(m => m.LegalSecurityPageModule)
  },

  {
    path: 'home/pcplanding',
    loadChildren: () => import('./pages/pcp-landing/pcp-landing.module').then(m => m.PcpLandingPageModule)
  },

  {
    path: 'home/marketingpreference',
    loadChildren: () => import('./pages/marketing-preference/marketing-preference.module').then(m => m.MarketingPreferencePageModule)
  },
  {
    path: 'home/mywirelanding',
    loadChildren: () => import('./pages/my-wire-landing/mywire-landing.module').then(m => m.MyWireLandingPageModule)
  },
  {
    path: 'home/relsurvey',
    loadChildren: () => import('./pages/rel-survey/rel-survey.module').then(m => m.RelSurveyPageModule)
  },
  {
    path: 'home/relsurveylanding',
    loadChildren: () => import('./pages/rel-landing/rel-landing.module').then(m => m.RelLandingPageModule)
  },
  
  {
    path: 'home/managemyaccount',
    loadChildren: () => import('./pages/manage-my-account/manage-my-account.module').then(m => m.ManageMyAccountPageModule)
  },
  {
    path: 'home/notificationslanding',
    loadChildren: () => import('./pages/notifications-landing/notifications-landing.module').then(m => m.NotificationsLandingPageeModule)
  },
  {
    path: 'home/notificationsdetails',
    loadChildren: () => import('./pages/notifications-details/notifications-details.module').then(m => m.NotificationsDetailsPageModule)
  },
  {
    path: 'home/planlimitslanding',
    loadChildren: () => import('./pages/plan-limits-landing/plan-limits-landing.module').then(m => m.PlanLimitsLandingPageModule)
  },
  {
    path: 'home/prevsummarypayment',
    loadChildren: () => import('./pages/previous-summary-of-payment/previous-summary-of-payment.module').then(m => m.PreviousSummaryOfPaymentPageModule)
  },
  {
    path: 'home/oophelptext',
    loadChildren: () => import('./pages/oop-help-text/oop-help-text.module').then(m => m.OOPHelpTextPageModule)
  },
  
  {
    path: 'home/summarypayment',
    loadChildren: () => import('./pages/summary-of-payment/summary-of-payment.module').then(m => m.SSummaryOfPaymentPageModule)
  },
  {
    path: 'home/summarypaymentfaq',
    loadChildren: () => import('./pages/summary-of-payment-faq/summary-of-payment-faq.module').then(m => m.SummaryOfPaymentFAQPageModule)
  },
  {
    path: 'home/taxformfaq',
    loadChildren: () => import('./pages/tax-form-faq/tax-form-faq.module').then(m => m.TaxFormFaqPageModule)
  },
  {
    path: 'home/taxformslanding',
    loadChildren: () => import('./pages/tax-forms-landing/tax-forms-landing.module').then(m => m.TaxFormsLandingPageModule)
  },
  {
    path: 'home/prevtaxforms',
    loadChildren: () => import('./pages/previous-tax-forms/previous-tax-forms.module').then(m => m.PreviousTaxFormsPageModule)
  },
  {
    path: 'home/provsearchinfo',
    loadChildren: () => import('./pages/provider-search-info/provider-search-info.module').then(m => m.ProviderSearchInfoPageModule)
  },
  {
    path: 'home/usfhppharmacy',
    loadChildren: () => import('./pages/usfhp-pharmacy/usfhp-pharmacy.module').then(m => m.USFHPPharmacyPageModule)
  },
  {
    path: 'home/wellnesslanding',
    loadChildren: () => import('./pages/wellness-landing/wellness-landing.module').then(m => m.WellnessLandingPageModule)
  },
  {
    path: 'home/memberlanding',
    loadChildren: () => import('./pages/member-landing/member-landing.module').then(m => m.MemberLandingePageModule)
  },
  {
    path: 'home/pdfviewer',
    loadChildren: () => import('./pages/pdfviewer/pdfviewer.module').then(m => m.PdvViewerPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/user-login/user-login.module').then(m => m.UserLoginPageModule)
  },
  {
    path: 'home/coordinationofbenefits',
    loadChildren: () => import('./pages/coordination-of-benefits/coordination-of-benefits.module').then(m => m.CoordinationOfBenefitsPageModule)
  }
  /*
  {
    path: '',
    //redirectTo: '/home', 
    redirectTo: 'userlogin',
    pathMatch: 'full'
    //loadChildren: () => import('./pages/user-login/user-login.module').then(m => m.UserLoginModule)
    //loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  
  }
  */

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}

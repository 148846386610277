import '@angular/compiler';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


const url = window.location.href;
if (url && (!url.includes('-pqa') && !url.includes('-d') && !url.includes('-fqa') && !url.includes('localhost'))) {
    enableProdMode();
}
/*
if (environment.production) {
  enableProdMode();
}*/

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MorePopComponent } from '../components/menu-pop-component/menu-pop-component';

@NgModule({
  declarations: [
    MorePopComponent 
  ],

  imports: [
    CommonModule,
    FormsModule,
    IonicModule,  
  ],
  exports: [
    MorePopComponent,
    
  ]
})
export class MorePopModule { }
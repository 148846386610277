import { Injectable } from '@angular/core';
//import 'rxjs/add/operator/map';
import {Storage} from '@ionic/storage';
//import { Storage } from '@ionic/storage-angular';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import CryptoJS from 'crypto-js';
//import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { Utilities } from './utilities';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage {
  public static FP_TOKEN = "fpToken";
  public static FLAG_ALTERNATE_LOGIN_TYPE = "flagAlternateLoginType";
  //public static FLAG_IS_SUBSEQUENT_LOGIN = "isSubsequentLogin";

  public static LOGIN_TYPE_PIN = "pin";
  public static LOGIN_TYPE_FINGERPRINT = "fingerprint";
  public static LOGIN_TYPE_CREDENTIALS = "credentials";

  public static ALTERNATE_LOGIN_EMAIL_ID = "emailId";

  public static IS_SUBSEQUENT_LOGIN_TRUE = "true";
  public static IS_SUBSEQUENT_LOGIN_FALSE = "false";

  public static APP_VERSION_UPDATE_ALERT = "appVersionUpdateAlert";


  public static APP_STORE_LINK = "appStoreLink";

  public static APP_RATER_FIRST_PROMPT = "appRaterFirstPrompt";
  public static APP_RATER_SUBSEQUENT_PROMPT = "appRaterSubsequentPrompt";
  public static IS_FIRST_PROMPT_SHOWN = "firstAppRaterPromptShown";
  public static APP_VERSION_IN_STORE = "appVersionInStore";

  public static TRUE = "true";
  public static NEVER = "never";
  public static FALSE = "false";
  public static IS_LOGIN_DATE_SAVED = "loginDateSaved";
  public static LOGIN_DATE = "loginDate";

  public static SHOW_MERGED_MESSAGE = "showMergedMessage"
  


  //public static USER_IGNROE_BIO_AND_SYS_PASSCODE = "userIgnoreBioAndSysCode";

  public static USER_ACCEPTED_LOGIN_TERMS_AND_COND = "true"; 

  public static SYS_PASSCODE_SELECTED = "sysPasscodeSelected";

  public static BIO_AND_PASSCODE_SELECTED = "bioAndPasscodeSelected";

  public static FACE_ID_SELECTED = "faceIdSelected";

  public static FINGER_PRINT_SELECTED = "fingerPrintSelectedd";

  public static BIO_OPTIONS_PRESENTED = "bioOptionsPresented";


  
  constructor(
     private storage: Storage,
     //private storage:NativeStorage ,
     public utilityHandler: Utilities) {
     //this.storage.create();
  }
  
  //constructor(private storage: NativeStorage, public utilityHandler: Utilities) {

  //}

  async setLocalStorageItem(key, value) {
    // window.localStorage.setItem(key, value);
    var uuidNo = this.utilityHandler.getUUIDNumber();
    var encryptedValue = CryptoJS.AES.encrypt(value, uuidNo);
    var setData = '{"value":"' + encryptedValue + '"}';
    await this.storage.set(key, setData);
  }

  async setUnsecureLocalStorageItem(key: string, value: any) {
    await this.storage.set(key, value);
  }

  async cgetUnsecureLocalStorageItem(key:string) {
    return await this.storage.get(key);
  }

  clearLocalStorage(){
    this.storage.clear();
  }

  removeLocalStorageItem(key:string) {
    return this.storage.remove(key);
  }
  
  

  /*
  getLocalStorageItem(key) : Promise<any>{
    // return window.localStorage.getItem(key);
    let fetchedValue: string = null;
    return new Promise((resolve, reject) => {
      this.storage.getItem(key)
        .then((value) => {
          try {
            console.log('getLocalStorageItem json :' ,value);
            var jsonData = JSON.parse(value);
            var data = jsonData.value;
            var uuidNo = this.utilityHandler.getUUIDNumber();
            var bytesValue = CryptoJS.AES.decrypt(data, uuidNo);
            fetchedValue = bytesValue.toString(CryptoJS.enc.Utf8);
            resolve(fetchedValue);
          } catch (e) {
            fetchedValue = ""
            console.log('catch error getLocalStorageItem :' ,value);
            resolve(fetchedValue);
          }
        }, (error) => {
          console.log(' error getLocalStorageItem  :' , error );
          reject(error);
        });
    });
    try{
      this.storage.getItem(key)
        .then(
          data => {
            
            console.log(data);
            var jsonData = JSON.parse(data);
            var data = jsonData.value;
            var uuidNo = this.utilityHandler.getUUIDNumber();
            var bytesValue = CryptoJS.AES.decrypt(data, uuidNo);
            fetchedValue = bytesValue.toString(CryptoJS.enc.Utf8);
            return fetchedValue;
          },
          error => console.error("getLocalStorageItem ", error)
        );
    }catch (ee){
      console.log('getLocalStorageItem catch block ',ee);
      var obj;
      return obj;
    }
 
}*/
   
async getLocalStorageItemAsync(key:string){
  try {
    const rawD  = await this.storage.get(key);
    if(rawD != null){
      var jsonData = JSON.parse(rawD);
      var data = jsonData.value;
      var uuidNo = this.utilityHandler.getUUIDNumber();
      var bytesValue = CryptoJS.AES.decrypt(data, uuidNo);
      data = bytesValue.toString(CryptoJS.enc.Utf8);
      return data;
    }
    return rawD;
  }catch (ee){
    //console.log('getLocalStorageItemAsync', ee);
    return null;
  }    
}


/*
async setLocalStorageItem1(key:string , val:any){
  var uuidNo = this.utilityHandler.getUUIDNumber();
  var encryptedValue = CryptoJS.AES.encrypt(val, uuidNo);
  var setData = '{"value":"' + encryptedValue + '"}';
    this.storage.setItem(key, setData)
   .then(
     () => console.log('Item stored for ' || key),
     error => console.error('Error in storing item for key ' || key, error)
   );
}*/
  

  async resetAlternativeLoginType() {
    var uuidNo = this.utilityHandler.getUUIDNumber();
    var encryptedValue = CryptoJS.AES.encrypt(LocalStorage.LOGIN_TYPE_CREDENTIALS, uuidNo);
    var setData = '{"value":"' + encryptedValue + '"}';
    await this.storage.set(LocalStorage.FLAG_ALTERNATE_LOGIN_TYPE, setData);
  }

}

import { Component, OnInit, ViewEncapsulation ,NgZone,isDevMode,ChangeDetectorRef} from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
//import { SwUpdate } from '@angular/service-worker';
import { SwUpdate } from '@angular/service-worker';
import { Platform ,ToastController} from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Utilities } from './providers/utilities';
import { WrongApp } from './providers/wrong-app-handler';
import {Adapter} from './providers/adapter';


import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {SessionHandler} from './providers/session-handler';
//import { SessionData } from './providers/session-data';

import { TeladocService } from './providers/teladoc-service';



//import { SplashScreen } from '@ionic-native/splash-screen';

//import { StatusBar } from '@capacitor/status-bar';
//import { SplashScreen } from '@capacitor/splash-screen';

//import { Storage } from '@ionic/storage';

declare var WLAuthorizationManager;



//

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  
  dark = false;
  appType: string='THP';
  offsetY;

  idleState = 'Not started.';

  constructor(
   
    private platform: Platform,
    private router: Router,
    private statusBar: StatusBar,
    private utilities: Utilities,
    private wrongApp: WrongApp,
    private changeDetectionRef: ChangeDetectorRef, 
    private toastCtrl: ToastController,
    private swUpdate: SwUpdate,
    private idle: Idle, 
    private keepalive: Keepalive,
    private teladoc: TeladocService, 
    private sessionHandler : SessionHandler,
    private adapterHandler : Adapter,
    private ngZone: NgZone
    
  ) {
    this.initializeApp();
    //MFP init is in assets/js/wlInit.js
    //document.addEventListener('wlcommoninitfired', this.getToken.bind(this), false);
  }

  async ngOnInit() {
    this.initIdle();
    this.swUpdate.versionUpdates.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
;
  }

  async initializeApp() {
    
    await this.platform.ready().then(() => {
      document.addEventListener('wlcommoninitfired', this.getToken.bind(this), false);
      if(! this.utilities.isMobileBrowser()){
        this.platform.resume.subscribe(() => this.ngZone.run(() => {
          this.wrongApp.checkForceAppDownload();
        }));
      }
    });
    
  }

  
  keyboardShowHandler(e) {
    let kH = e.keyboardHeight;
    let bodyMove = <HTMLElement>document.querySelector("ion-app"), bodyMoveStyle = bodyMove.style;
    if (this.offsetY < kH + 40) {
      bodyMoveStyle.bottom = (kH - this.offsetY + 40) + "px";
      bodyMoveStyle.top = "initial";
    }
  }
  
  getToken() {
    console.log('Get MFP Token');
    var self = this;
    WLAuthorizationManager.obtainAccessToken().then(
      function(accessToken) {
          console.log("Succeed to reach MFP");
          self.initApp();
      },
      function(error) {
        console.log('error in getting MFP token', error);
        console.log("Failed to reach MFP");
      }
    );  
  }

  initApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.adapterHandler.getMFPTokenProviderDataAsync();
      if (this.platform.is('hybrid')){
        window.addEventListener('native.keyboardshow', this.keyboardShowHandler);
      }
      this.changeDetectionRef.detectChanges();
    });
    
  }

  initIdle(){
    this.idle.setIdle(150); // seconds
    this.idle.setTimeout(150); //seconds, user get timeout message after 5 min (total 150+150 seconds)
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      //this.reset();
    });
    
    this.idle.onTimeout.subscribe(() => {
      this.stopIdleTimer();
      this.stopKeepAlive();
      this.sessionHandler.callLogout();
    });
    
    this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log('onIdleStart', this.idleState);
    });
    
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log('onIdleWarning', this.idleState);
      //console.log('onTimeoutWarning', this.idleState);
    });

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        var currentPath = val.url;
        if(currentPath.search(/home/gi) > 0) {
          //console.log('start timer');
          this.startIdleTimer()
        }else{
          this.stopIdleTimer();
        }
      }
    });
    this.teladoc.startKeepAlive = this.startKeepAlive.bind(this);
    this.teladoc.stopKeepAlive =  this.stopKeepAlive.bind(this)
    this.teladoc.stopIdleTimer = this.stopIdleTimer.bind(this);
    this.teladoc.startIdleTimer = this.startIdleTimer.bind(this);

  }

  startKeepAlive(): void {
    this.keepalive.interval(10);
    this.keepalive.start();  
  }
  stopKeepAlive(){
    if(this.keepalive){
      this.keepalive.stop();
    }
  }

  stopIdleTimer() {
    if(this.idle.isRunning()){
      this.idle.stop();
    }
  }

  startIdleTimer() {
    if (!this.idle.isRunning()) {
     this.idle.watch();
    }
  }
}

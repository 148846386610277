import { Injectable } from '@angular/core';
//import { LoadingController } from 'ionic-angular/index';
import { LoadingController } from '@ionic/angular';

//import { AlertController, Platform, App, Events} from 'ionic-angular';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
//import { AppVersion } from '@ionic-native/app-version';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import {WrongApp} from './wrong-app-handler';
 

import 'rxjs/add/operator/map';

import { Utilities } from './utilities';
import { SessionData } from './session-data';
import { LocalStorage } from './local-storage';
import { PdfHandler } from './pdf-handler';
import { Market } from '@ionic-native/market/ngx';

//import { IdentityService } from './identity-service';

declare var WL;
declare var WLResourceRequest;

@Injectable({
  providedIn: 'root',
})
export class Adapter {
  public static DEVICE_TYPE = "mobile";
  public static readonly VALIDATE_MEMBER = "validateMember";
  public static readonly VALIDATE_OTP = "validateOTP";
  public static readonly VALIDATE_QUIZ = "validateQuiz";
  public static readonly ADD_USER = "addUser";
  public static readonly RSA_ANALYZE = "analyze";
  public static readonly RSA_CHALLENGE = "challenge";
  public static readonly RSA_VERIFY_LOGIN_OTP = "verifyLoginOTP";
  public static readonly RSA_VERIFY_LOGIN_IDENTITY_QUESTIONS = "verifyLoginIdentityQuestions";
  public static readonly VALIDATE_LOGIN_PIN = "validateLoginPIN";

  public static readonly RESET_PASSWORD = "resetPassword";
  public static readonly VERIFICATIONS_PASSWORD = "verificationsPassword";
  public static readonly SET_PASSWORD = "setPassword";
  public static readonly GET_IDENTITY_QUESTION = "getIdentityQuestions";

  public static readonly GET_EMAIL = "getEmail";
  public static readonly VERIFICATIONS_EMAIL = "verificationsEmail";

  public static readonly GET_MEMBER_PROFILE_DATA = "getMemberProfileData";
  public static readonly GET_MEMBER_PROFILE_DATA_V2 = "getMemberProfileDatav2";
  public static readonly GET_MEMBER_PROFILE_DATA_V3 = "getMemberProfileDatav3";
  public static readonly GET_MEMBERS_ON_PLAN = "getMembersOnPlan";
  public static readonly GET_BENEFITS = "getBenefits";

  public static readonly GET_PLAN_LIMITS = "getPlanLimits";
  public static readonly GET_OUT_OF_POCKET = "getOutOfPocket";

  public static readonly GET_CLAIMS = "getClaims";
  public static readonly GET_CLAIMS_V2 = "getClaimsv2";
  public static readonly GET_CLAIM_DETAILS = "getClaimDetails";

  public static readonly SET_DOC_PREFERENCES = "setDocPreferences";
  public static readonly SET_DOC_PREFERENCES_V2 = "setDocPreferencesv2";
  public static readonly GET_DOC_PREFERENCES = "getDocPreferences";
  public static readonly GET_DOC_PREFERENCES_V2 = "getDocPreferencesv2"
  public static readonly GET_MARKETING_PREFS = "getMarketingPreferences";
  public static readonly SET_MARKETING_PREFS = "setMarketingPreferences";
  public static readonly SET_FORCE_CHANGE_PASSWORD = "setForceChangePassword";

  public static readonly GET_ID_CARD = "getIdCard";
  public static readonly FAX_ID_CARD = "faxIdCard";
  public static readonly EMAIL_ID_CARD = "emailIdCard";

  public static readonly GET_SOP_PDF = "sopPDF";
  public static readonly GET_EOB_PDF = "eobPDF";
  public static readonly GET_SOP_QUATERS = "memberSOPHistory";

  public static readonly LOGOUT_USER = "logOut";
  public static readonly SESSION_LOGGEDOFF = "LOGGEDOFF";
  public static readonly PREPARE_SSO = "prepareProviderSearch";
  public static readonly GET_EOB_URL = "eobUrl";
  public static readonly GET_MEMBER_CONTACT_DETAILS = "contactDetails";
  public static readonly GET_REFERRALS = "getReferrals";
  public static readonly GET_AUTHORIZATIONS = "getAuthsAndNotifications";
  public static readonly SET_MOBILE_PHONE = "setMobilePhone";
  public static readonly SET_SECURITY_QUESTION = "setSecurityQuestions";
  public static readonly GET_FAQ_CONTENT = "getFAQContent";
  public static readonly GET_REUTERS_URL = "getReutersUrl";
  public static readonly UPDATE_REL_INFO = "updateRELData";
  public static readonly UPDATE_SOGI_INFO = "updateSOGIData";
  public static readonly GET_TAX_FORMS = "getTaxForms";
  public static readonly GET_REL_INFO = "getRELData";
  public static readonly GET_SOGI_INFO = "getSOGIData";
  public static readonly GET_TAX_PDF = "taxFormPDF";
  public static readonly GET_APP_INFO = 'getAppInfo';
  public static readonly UPDATE_OPTOUT_FLAG = 'updateOptoutFlag';
  public static readonly LOG_ANALYTICS= "audit";
  public static readonly GET_MEMBER_IDENTITY = "getMemberIdentity";
  public static readonly GET_TELADOC_LOGIN_TOKEN = "getTeladocLoginToken";
  public static readonly GET_AEOBS = 'getAeobs';
  public static readonly GET_AEOB_PDF = 'getAeobPdf';
  


  private mfpSecuredToken: string;l
  loadingPopup: any;
  isLoading = false;

  constructor(public utilityHandler: Utilities, 
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private sessionDataHandler: SessionData, 
    private alertCtrl: AlertController,
    private app: AppVersion,
    private wrongApp: WrongApp,
    //private appHandler: App,
    private localStorageHandler: LocalStorage,
    private pdfHandler: PdfHandler,
    private market: Market
    // private events: Events,
    ) {
  }


  async sendLogForAnalytics(key, value): Promise<void> {
    var actualAdapterPath = "/adapters/TuftsDataSource/" + Adapter.LOG_ANALYTICS
    var resourceRequest = new WLResourceRequest(actualAdapterPath,
        WLResourceRequest.POST);
    var params = [await this.utilityHandler.createAnalyticsJson(key, value), Adapter.LOG_ANALYTICS];
    var newParams = { 'params': JSON.stringify(params) };
    try {
        await resourceRequest.sendFormParameters(newParams);  
    } catch (e ) {
        console.log(e);
    }  
  }

  getCurrentAppVersion() {
    if (  !this.utilityHandler.isMobileBrowser()) { // no need to check version for browser
      var self = this;
      var resourceRequest = new WLResourceRequest("/adapters/MFPToken/getCurrentAppVersionOnStore", WLResourceRequest.GET);
        let appType:string ;
        this.utilityHandler.getAppType().then(value => {
          appType = value;
          resourceRequest.send().then(
            response => {
              var responseJson = JSON.parse(response.responseText);
            
              const currentVersionOnStore: string = responseJson.currentVersion;
              this.utilityHandler.ipAddress = responseJson.ipAddress;
              this.sessionDataHandler.logoutURLDomain = responseJson.logoutURLDomain;
             
              this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_RATER_FIRST_PROMPT, responseJson.appRaterFirstPrompt);
              this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_RATER_SUBSEQUENT_PROMPT, responseJson.appRaterSubsequentPrompt);
              this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_VERSION_IN_STORE, currentVersionOnStore);

              if (this.platform.is('ios')) {
                switch (appType) {
                  case 'USFHP': {
                    this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_STORE_LINK, responseJson.usfhpAppStoreLink);
                    break;
                  }
                  case 'THP': {
                    this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_STORE_LINK, responseJson.appStoreLink);
                    break;
                  }
                }
              } else if (this.platform.is('android')) {
                switch (appType) {
                  case 'USFHP': {
                    this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_STORE_LINK, responseJson.usfhpPlayStoreLink);
                    break;
                  }
                  case 'THP': {
                    this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_STORE_LINK, responseJson.playStoreLink);
                    break;
                  }
                }
              }
              
              self.app.getVersionNumber().then((s) => {
                var currentVersionOnApp: string = s;
                const currentV = currentVersionOnApp.split('.');
                const storeV =  currentVersionOnStore.split('.');
                if(currentV  && storeV){
                  if( currentV[0] < storeV[0]){
                    this.wrongApp.forceDownloadApp();
                  }else if( currentV[0] == storeV[0] && ((currentV[1] < storeV[1]) ||
                          (currentV[1] == storeV[1] && (currentV[2] < storeV[2])))){

                      this.localStorageHandler.getLocalStorageItemAsync(LocalStorage.APP_VERSION_UPDATE_ALERT).then ((version) => {
                      const ver = version;
                      if(currentVersionOnStore != ver){
                        this.localStorageHandler.setLocalStorageItem(LocalStorage.APP_VERSION_UPDATE_ALERT, currentVersionOnStore);
                        this.wrongApp.appVersionAvailable();
                      }
                    });                   
                  }
                }
              })
            },
            error => {
            });

        }).catch(err => {
        });
    }
  }
  
  

  getMFPTokenProviderDataAsync(): Promise<void> {
    return new Promise((resolve, reject) => {
      var resourceRequest = new WLResourceRequest("/adapters/MFPToken/getMFPToken",
      WLResourceRequest.GET);
    resourceRequest.send().then(
      response => {
        var responseJson = JSON.parse(response.responseText);
        this.mfpSecuredToken = responseJson.access_token;
        this.getCurrentAppVersion();
        resolve();
      },
      error => {
        reject();
      });
    });
  }

  async getSMAuthReasonProviderDataAsync(username, password, continueLoader?: boolean) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
      });
      loading.present();
    //this.showLoader();
    return new Promise((resolve, reject) => {
      var params = [username, password];
      var newParams = { 'params': JSON.stringify(params) };
      this.callSMAuthAdapter(newParams).then(
        (response: any) => {
          //var smAuthReason = response.SMAUTHREASON;
          //var smSession = response.SMSESSION;
          //if ((smAuthReason == "-1" || smAuthReason == "18" || smAuthReason == "0") && smSession.length > 1 && smSession != Adapter.SESSION_LOGGEDOFF) { //valid user
            //if (!continueLoader) {
            //}
          //} else {
            //this.hideLoader();
          //}
          //this.hideLoader();
          loading.dismiss();
          resolve(response);
        },
        (error: any) => {
          //this.hideLoader();
          loading.dismiss();
          var resJson = error;
          var smSession = resJson.SMSESSION;
          if (smSession == Adapter.SESSION_LOGGEDOFF) {
            this.callSMAuthAdapter(newParams).then(
              (response: any) => {
                var smAuthReason = response.SMAUTHREASON;
                var smSession = response.SMSESSION;
                if ((smAuthReason == "-1" || smAuthReason == "18" || smAuthReason == "0") && smSession.length > 1 && smSession != Adapter.SESSION_LOGGEDOFF) { //valid user
                  if (!continueLoader) {
                    //this.hideLoader();
                    loading.dismiss();
                  }
                } else {
                  //this.hideLoader();
                  loading.dismiss();
                }
                resolve(response);
              },
              (error: any) => {
                //this.hideLoader();
                loading.dismiss();
                reject(false);
              });
          } else {
            //this.hideLoader();
            loading.dismiss();
            //reject(new Error(error.message));
            reject(error);
            
          }
        });
    });
  }

  callSMAuthAdapter(params) {
   
    return new Promise((resolve, reject) => {
      
      var resourceRequest = new WLResourceRequest("/adapters/SMAuthJava/getSMAuthCode",
        WLResourceRequest.POST);
      resourceRequest.sendFormParameters(params).then(
        (response: any) => {
          var resJson: any = JSON.parse(response.responseText);
          var smSession = resJson.SMSESSION;
          this.sessionDataHandler.smSessionToken = smSession;
          if (smSession == Adapter.SESSION_LOGGEDOFF) {
            reject(resJson);
          }
          else {
            resolve(resJson);
          }
        },
        (error: any) => {
          //reject(new Error(error.errorCode));
          reject(error);
        })
    });
  }

  async securedPostRequest(dataToSend, methodName, continueLoader?: boolean) {
    //this.showLoader();
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
      });
      loading.present();
    var actualAdapterPath = "/adapters/TuftsSecuredDataSource/" + methodName;
    return new Promise((resolve, reject) => {
      var resourceRequest = new WLResourceRequest(actualAdapterPath,
        WLResourceRequest.POST);
      resourceRequest.setHeader("Cookie", "SMSESSION=" + this.sessionDataHandler.smSessionToken);
      var params = [dataToSend, methodName, this.sessionDataHandler.smSessionToken];
      var newParams = { 'params': JSON.stringify(params) };
      resourceRequest.sendFormParameters(newParams).then(
        response => {
          //if (!continueLoader) {
            loading.dismiss();
          //}
          var json = JSON.parse(response.responseText);
          var smSessionRaw = response ? JSON.stringify(response) : "";
          var smSessionValue = "";
          if (smSessionRaw.indexOf('SMSESSION=') != -1) {
            smSessionValue = smSessionRaw.substring(smSessionRaw.indexOf('SMSESSION=') + 10, smSessionRaw.indexOf(';'));

          }
          if (smSessionValue != "") {
            this.sessionDataHandler.smSessionToken = smSessionValue;
          }
          // Session logged off - clearing session and navigating user to login screen
          //let smHeader = response.headers.get('SMSESSION');
          //console.log("===Headers===>   " + smHeader);
          if (smSessionValue && (smSessionValue == Adapter.SESSION_LOGGEDOFF)) { // || smHeader == Adapter.SESSION_LOGGEDOFF)) {
            loading.dismiss();
            this.utilityHandler.showAlert("Session Time out", "", 
                "You have reached the session limit. For your protection, sessions are open for a limited period of time on our mobile application. For your security you must log back into the application.", "OK");
            /*var _alert = this.alertCtrl.create({
	             title: "Session Time out", message: "You have reached the session limit. For your protection, sessions are open for a limited period of time on our mobile application. For your security you must log back into the application.", enableBackdropDismiss: false, buttons: ['OK']
            });*/
            json.statusCode = 422; //to prevent any more alerts
            /*_alert.onDidDismiss(() => {
              const overlayView = this.appHandler._appRoot._overlayPortal._views[0];
              if (overlayView && overlayView.dismiss) {
                overlayView.dismiss();
              }
              this.events.publish('logout:called');
            });
            _alert.present();*/
          }
          resolve(json);
        },
        error => {
          loading.dismiss();
          var json = JSON.stringify(error);
          var smSessionValue = json.substring(json.indexOf('SMSESSION=') + 10, json.indexOf(';'));
          if (smSessionValue && smSessionValue == Adapter.SESSION_LOGGEDOFF) {
            //json = "";
            /*var alert = this.alertCtrl.create({
              title: "Session Time out", message: "You have reached the session limit. For your protection, sessions are open for a limited period of time on our mobile application. For your security you must log back into the application.", enableBackdropDismiss: false, buttons: ['OK']
            });*/
            this.presentAlert2("Session Time out","You have reached the session limit. For your protection, sessions are open for a limited period of time on our mobile application. For your security you must log back into the application.", "OK");
            /*alert.onDidDismiss(() => {
              const overlayView = this.appHandler._appRoot._overlayPortal._views[0];
              if (overlayView && overlayView.dismiss) {
                overlayView.dismiss();
              }
              this.sessionDataHandler.clearSessionData()
            });
            alert.present();*/
          }
          reject(error);
        });
    });
  }

  
  async presentAlert2(head:string,msg:string, buttonLabel:string) {
    const alert = await this.alertCtrl.create({
      //cssClass: 'my-custom-class',
      header: head,
      message: msg,
      buttons: [
        {
          text: buttonLabel,
          role: buttonLabel,
          id: 'ok-button',
          handler: () => {
            console.log('Confirm OK:');
            this.sessionDataHandler.clearSessionData();
          }
        }
      ]
    });
    await alert.present();
  }
  

  async postRequest(dataToSend, methodName, continueLoader?: boolean) {
    
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
      });
      loading.present();
    var actualAdapterPath = "/adapters/TuftsDataSource/" + methodName;
    return new Promise((resolve, reject) => {
      var resourceRequest = new WLResourceRequest(actualAdapterPath,
        WLResourceRequest.POST);
      var params = [dataToSend, methodName];
      var newParams = { 'params': JSON.stringify(params) };
      resourceRequest.sendFormParameters(newParams).then(
        response => {
          if (!continueLoader) {
            loading.dismiss();
          }
          var json = JSON.parse(response.responseText);
          resolve(json);
        },
        error => {
          console.log('error ',JSON.stringify(error));
          loading.dismiss();
          //var json11 =  JSON.parse(error);
          reject(error);
        });
    });
  }

  async getIdentityQuestion() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
      });
      loading.present();
    var actualAdapterPath = "/adapters/TuftsDataSource/" + Adapter.GET_IDENTITY_QUESTION;
    return new Promise((resolve, reject) => {
      var resourceRequest = new WLResourceRequest(actualAdapterPath,
        WLResourceRequest.POST);
      var params = [Adapter.GET_IDENTITY_QUESTION, this.sessionDataHandler.memberType];
      var newParams = { 'params': JSON.stringify(params) };
      resourceRequest.sendFormParameters(newParams).then(
        response => {
          loading.dismiss();
          var json = JSON.parse(response.responseText);
          resolve(json);
        },
        error => {
          var json = JSON.stringify(error);
          loading.dismiss();
          reject(json);
        });
    });
  }
  
  callAnalyzeWithAppType(userId: string, appType: string): Promise<any>{
    return new Promise((resolve, reject) => {
      var params = {};
      params["userId"] = userId;
      params["userType"] = "member";
      params["uuidNumber"] = this.utilityHandler.getUUIDNumber();
      params["ipAddress"] = this.utilityHandler.ipAddress;
      params["devicePrint"] = this.utilityHandler.getDevicePrint();
      params["appType"] = appType;
      this.postRequest(JSON.stringify(params), Adapter.RSA_ANALYZE)
        .then((response: any) => {
          //If analyze API returns status = 1 - Go to home screen
          //else status = 0 - show challenge list screen
          //console.log('response.challengeList *** ',  response);
          if (response.status) {
            this.sessionDataHandler.securityToken = response.securityToken;
            this.sessionDataHandler.memberId = response.memberId;
            if (response.challengeList) {
              //Need to show challenge list/ OTP, so save data
              this.sessionDataHandler.transactionId = response.transactionId;
              this.sessionDataHandler.sessionId = response.sessionId;
              this.sessionDataHandler.deviceTokenCookie = response.deviceTokenCookie;
             
            }
          }
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }
  
  async callAnalyzeAPI(userId: string): Promise<any> {
    let appType = await this.utilityHandler.getAppType();
    //let appType:string;
    /*this.utilityHandler.getAppType().then(value => {
      appType = value;
    }).catch(err => {
    });*/
    return this.callAnalyzeWithAppType(userId, appType);
  }
  
  getPDF(params, url) {

    let pdfFilename = params["reportQuarter"] != undefined ? params["reportQuarter"] : params["claimNumber"];
    return new Promise((resolve, reject) => {

      this.securedPostRequest(JSON.stringify(params), url)    
      .then((data: any) => {
        var responseCode = data.statusCode;
        if (responseCode == 200) {
          if(url == Adapter.GET_TAX_PDF){
            this.pdfHandler.createAndOpenPdf(data.outPdfForm,  params["inFormID"]+'.pdf');
          }else{
            this.pdfHandler.createAndOpenPdf(data.productPdf, pdfFilename +'.pdf');
          }
        } else if (responseCode == 422) {
          var messageType = data.messages[0].messageType;
          var message = data.messages[0].message;
          if (messageType && message) {
            this.utilityHandler.showAlert("Document Unavailable", "",message, "OK");
          }
          reject(true);
        } else {
          this.utilityHandler.serviceUnknownError();
        }
      }, (err) => {
        if (err) {
          this.utilityHandler.serviceError(err);
          reject(true);
        }
      });
    });
  }
  public handleLoginAnalytics(type: string){
    let tag: string;
    let description: string;
    switch (type) {
      case 'Biometrics':
          tag =  'MMA_LOGIN_FACE_ID_TOUCH_ID';
          description = 'Login Page - Face ID / Touch ID Login Success';
          break;
      case 'Both':
          tag =  'MMA_LOGIN_FACE_ID_TOUCH_ID_SYSTEM_ID';
          description = 'Login Page - Face ID / Touch ID System Passcode Login Success';
          break;
      case 'SystemPasscode':
          tag =  'MMA_SYSTEM_PASSCODE';
          description = 'Login Page - SYSTEM Passcode Login Success';
          break;
      default:  
          //Just in case for something else I have this, I put "other". Some Samsung phones have iris scanning and such. I can also just use password.
          tag =  'MMA_LOGIN_NON_BIOMETRIC';
          description = 'Login Page - Non Biometric Login Success';
    }
    this.sendLogForAnalytics([Utilities.ACTION_CODE, Utilities.ACTION_DESCRIPTION], [tag, description]);
  }


  private getAppleAppId(packageName: string) {
    return this.postRequest(packageName, Adapter.GET_APP_INFO, false).then((appInfo: any) => appInfo.trackId);
  }

  public showWrongAppAlert(msg: string, mobileWebUrl: string) {
        
    let appType:string;
    this.utilityHandler.getAppType().then(async value => {
      appType = value;
      //let appType = this.utilities.appType;
      let appOption = {
        text: 'Download the App',
        cssClass: 'alert-button-text',
        handler: () => {
          let packageName = this.utilityHandler.getMembersPackageName(mobileWebUrl);
          //console.log('pack ',packageName);
          if(this.platform.is('ios')) {
            if (this.utilityHandler.isMobileBrowser()) {
              this.getAppleAppId(packageName).then((appId: string) => {
                //console.log('appId name ', appId);
                this.utilityHandler.openLink('itms-apps://apps.apple.com/app/apple-store/id' + appId + '?mt=8');
              });
            } else {
              if("USFHP" ==  appType){
                this.market.open('https://apps.apple.com/us/app/usfhp-of-southern-new-england/id1474986277?mt=8');
                
              }else {
                this.market.open('https://apps.apple.com/us/app/tufts-health-plan/id1289408406?mt=8');
              }
              /*
              this.getAppleAppId(packageName).then((appId: string) => {
                console.log('appId **',appId);
                console.log('package name ', packageName);
                this.market.open('https://apps.apple.com/app/apple-store/id' + appId + '?mt=8');
              });*/
            }
          } else if (this.platform.is('android')) {
            if (this.utilityHandler.isMobileBrowser()) {
              this.utilityHandler.openLink('https://play.google.com/store/apps/details?id=' + packageName);
            } else {
              this.market.open(packageName);
            }
          }
        }
      };
      let mobileWebOption = {
          text: 'Redirect to Mobile Web',
          cssClass: 'alert-button-text',
          handler: () => {
            this.utilityHandler.openLink(mobileWebUrl);
          }
      };
      let closeButton = {
        text: 'Close',
        cssClass: 'alert-button-text',
        handler: () => {
        }
      };
      let buttonsArray = [];
      if (appType == 'THP' || appType == 'USFHP') {
        buttonsArray.push(appOption);
      }
      if (mobileWebUrl && mobileWebUrl.length > 0) {
        buttonsArray.push(mobileWebOption);
      }
      let branding = appType.toLowerCase() + ' point32healthsans-text';
      buttonsArray.push(closeButton);
      let alert = await this.alertCtrl.create({
        //title: 'Wrong App',
        message: msg,
        cssClass: branding,
        //enableBackdropDismiss: false,
        buttons: buttonsArray
      });
      await alert.present();
    }).catch(err => {
    });

    
}

}

import { Injectable } from '@angular/core';
import { Utilities } from './utilities';
import { Platform, ModalController } from '@ionic/angular';
//import { File, FileEntry } from '@ionic-native/file';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
//import { FileOpener } from '@ionic-native/file-opener';
import  {PdfViewerPage} from '../pages/pdfviewer/pdfviewer';

@Injectable({
  providedIn: 'root',
})
export class PdfHandler {

  constructor(private utilityHandler: Utilities,
    private platform: Platform,
    private file: File,
    private fileOpener: FileOpener,
    private modalCtrl: ModalController,
  ) {
  }

  createAndOpenPdf(bytes, filename: string) {
    var nameArr = filename.split('.');
    if (nameArr[1] != 'pdf' && filename) {
      filename = filename + '.pdf'
    }
    if (filename && bytes) {
      fetch("data:application/pdf;base64," + bytes).then(
        function (resp) {
          return resp.blob()
        }).then(blob => {
          if (!this.utilityHandler.isMobileBrowser() && this.platform.is('ios')) {
            this.file.writeFile(this.file.tempDirectory, filename, blob, { replace: true }).then((data: any) => {
              this.fileOpener.open(this.file.tempDirectory + filename, 'application/pdf');
            });
          } else if (!this.utilityHandler.isMobileBrowser() && this.platform.is('android')) {
            console.log('=====About to write:   ');
            this.file.writeFile(this.file.dataDirectory, filename, blob, { replace: true }).then(async (data: any) => {
              console.log('=====Finished write:   ', data.toURL());
              /*await this.fileOpener.open(data.toURL(), 'application/pdf').then((data) => {
                console.log('=====Finished open:   ', data);
              }).catch((error) => {
                console.log('=====Error on open:   ', error);
              });*/
              this.fileOpener.open(this.file.dataDirectory + filename, 'application/pdf');
            });
          } else if (this.utilityHandler.isMobileBrowser()) {
            if (this.platform.is('ios')) {
              //let modalPopUp = this.modalCtrl.create(PdfViewerComponent, { pdfUrl: URL.createObjectURL(blob), fileName: filename, border: false });
              this.presentModal(URL.createObjectURL(blob),filename,false);
            } else {
              this.utilityHandler.openLink(URL.createObjectURL(blob));
            }
          }
        });
    }
  }

  async createPDF(bytes, filename: string): Promise<string> {
    let nameArr = filename.split('.');
    //let pdfPath: FileEntry | string;
    let  pdfPath;
    if (nameArr[1] != 'pdf' && filename) {
      filename = filename + '.pdf'
    }
    if (filename && bytes) {
      pdfPath = await fetch("data:application/pdf;base64," + bytes).then(
        function (resp) {
          return resp.blob()
        }).then(async blob => {
          try {
            if (this.utilityHandler.isMobileBrowser()) {
              return URL.createObjectURL(blob);
            }
            if (this.platform.is('ios')) {
              return await this.file.writeFile(this.file.tempDirectory, filename, blob, { replace: true });
            } else if (this.platform.is('android')) {
              return await this.file.writeFile(this.file.dataDirectory, filename, blob, { replace: true });
            }
            throw new Error("Unsupported");
          } catch (error) {
            this.utilityHandler.showAlert(
              "Platform Not Supported", "",
              "PDF opening is not supported by your device, please use a desktop browser to see this PDF.", "OK"
            );
          }
        });
    }
    if (typeof pdfPath === "object") {
      return pdfPath.nativeURL;
    } else {
      return pdfPath;
    }
  }

  async createLocalUrl(bytes, filename: string): Promise<string> {
    let nameArr = filename.split('.');
    if (nameArr[1] != 'pdf' && filename) {
      filename = filename + '.pdf'
    }
    if (filename && bytes) {
      return await fetch("data:application/pdf;base64," + bytes).then(
        function (resp) {
          return resp.blob();
        }).then(async blob => {
          return URL.createObjectURL(blob);
        });
    }
  }

  openPdf(pathToFile: string, fileName?: string, useBrowser?: boolean): void {
    if (this.utilityHandler.isMobileBrowser() || useBrowser) {
      if (this.platform.is('ios') && !useBrowser) {
        //let modalPopUp = this.modalCtrl.create(PdfViewerComponent, { pdfUrl: pathToFile, fileName: fileName, border: false });
        //modalPopUp.present();
        this.presentModal(pathToFile,fileName,useBrowser);
      } else {
        this.utilityHandler.openLink(pathToFile);
      }
    } else if (this.platform.is('ios')) {
      this.fileOpener.open(this.file.tempDirectory + pathToFile, 'application/pdf');;
    } else if (this.platform.is('android')) {
      this.fileOpener.open(pathToFile, 'application/pdf')
    } else {
      this.utilityHandler.showAlert(
        "Platform Not Supported", "",
        "PDF opening is not supported by your device, please use a desktop browser to see this PDF." , "OK"
      );
    }
  }

  async presentModal( pdfUrl: string, fileName: string, border: boolean ) {
    const modal = await this.modalCtrl.create({
      component: PdfViewerPage,
      swipeToClose: true,
      componentProps: { pdfUrl: pdfUrl, fileName: fileName, border },
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  deletePdf(filename: string) {
    var nameArr = filename.split('.');
    if (!(nameArr[1] == 'pdf') && filename) {
      filename = filename + '.pdf'
    }
    if (!this.utilityHandler.isMobileBrowser() && this.platform.is('ios')) {
      this.file.removeFile(this.file.tempDirectory, filename);
    } else if (!this.utilityHandler.isMobileBrowser() && this.platform.is('android')) {
      this.file.removeFile(this.file.cacheDirectory, filename);
    }
  }
}
